import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
    BaseComponent,
    InputErrorComponent,
    LoadingSpinnerComponent,
    SeasonTicket,
    SeasonTicketVariant,
    ServiceListPipe,
    ToastService,
} from '@shared';
import { finalize } from 'rxjs';
import { SeasonTicketService, SeasonTicketVariantService } from '../../services';

@Component({
    selector: 'solma-app-buy-season-ticket',
    templateUrl: 'component.html',
    standalone: true,
    imports: [
        TranslateModule,
        CommonModule,
        ServiceListPipe,
        LoadingSpinnerComponent,
        ReactiveFormsModule,
        InputErrorComponent,
    ],
    providers: [ServiceListPipe],
})
export class BuySeasonTicketComponent extends BaseComponent implements OnInit {
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly seasonTicketVariantService = inject(SeasonTicketVariantService);
    private readonly seasonTicketService = inject(SeasonTicketService);
    private readonly toastService = inject(ToastService);

    protected variant: SeasonTicketVariant | null = null;
    protected isLoading = false;
    protected readonly companyKeys: Array<keyof SeasonTicket> = [
        'ico',
        'dic',
        'icdph',
        'address',
        'country',
        'city',
        'postal_code',
    ];

    protected readonly form = new FormGroup(
        {
            name: new FormControl(null, [Validators.required]),
            phone: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            is_company: new FormControl<boolean>(false, [Validators.required]),
            ico: new FormControl(null),
            dic: new FormControl(null),
            icdph: new FormControl(null),
            address: new FormControl(null),
            country: new FormControl(null),
            city: new FormControl(null),
            postal_code: new FormControl(null),
            confirm: new FormControl<boolean>(false, {
                nonNullable: true,
                validators: [Validators.requiredTrue],
            }),
        },
        {
            validators: (control: AbstractControl) => {
                const value = control.getRawValue();
                if (value.is_company) {
                    this.companyKeys.forEach((key) => {
                        if (!value[key]) {
                            control.get(key)?.setErrors({ required: true });
                        }
                    });
                }
                return null;
            },
        }
    );

    ngOnInit(): void {
        //variant id
        const id = this.route.snapshot.paramMap.get('variantId');
        if (!id) {
            this.router.navigateByUrl('/', { replaceUrl: true });
            return;
        }

        this.isLoading = true;
        this.subscribe(
            this.seasonTicketVariantService.getById(parseInt(id)).pipe(finalize(() => (this.isLoading = false))),
            {
                next: (variant) => (this.variant = variant),
                error: (_) => this.router.navigateByUrl('/', { replaceUrl: true }),
            }
        );

        this.subscribe(this.form.controls['is_company'].valueChanges, {
            next: (is_company) => {
                this.companyKeys.forEach((key) => {
                    if (!is_company) {
                        this.form.get(key)?.reset();
                    }
                });
            },
        });
    }

    save() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        if (!this.variant) return;

        const value = this.form.getRawValue();
        this.isLoading = true;
        this.subscribe(
            this.seasonTicketService
                .buy({
                    ...value,
                    season_ticket_variant_id: this.variant.id,
                } as any)
                .pipe(finalize(() => (this.isLoading = false))),
            {
                next: (response) => {
                    if ('gw_url' in response) {
                        this.form.reset();
                        window.location.href = response.gw_url;
                        return;
                    }

                    if (response.error) {
                        this.toastService.showError(response.message);
                    }
                },
            }
        );
    }
}
