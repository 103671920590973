import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LoadingSpinnerComponent } from '@shared';
import { PaymentService } from '../../services';
import { PaymentVerificationComponent } from '../payment-verification';

@Component({
    selector: 'solma-app-season-ticket-payment-verification',
    templateUrl: '../payment-verification/component.html',
    standalone: true,
    imports: [LoadingSpinnerComponent, RouterLink],
})
export class SeasonTicketPaymentVerificationComponent extends PaymentVerificationComponent {
    private readonly service = inject(PaymentService);
    protected paymentVerification = this.service.seasonTicketPaymentNotification.bind(this.service);
}
