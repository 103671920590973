<!--==============================
    Mobile Menu
  ============================== -->
<div class="vs-menu-wrapper vs-body-visible">
    <div class="vs-menu-area text-center">
        <button (click)="close()" class="vs-menu-toggle"><i class="fal fa-times"></i></button>
        <div class="mobile-logo">
            <a href="index.html"><img src="assets/img/logo.png" alt="Wellnez" /></a>
        </div>
        <div class="vs-mobile-menu">
            <ul>
                @for (item of links(); track $index) {
                <li
                    [ngClass]="{
                    'menu-item-has-children': !!item.children?.length
                }">
                    <a [routerLink]="item.link" (click)="navigationClicked(item.link)"
                        >{{ 'menu.links.' + item.label | translate }}</a
                    >
                    @if (!!item.children?.length) {
                    <ul class="sub-menu">
                        @for (child of item.children; track $index) {
                        <li>
                            <a [routerLink]="child.link" (click)="navigationClicked(child.link)"
                                >{{ 'menu.links.' + child.label | translate }}</a
                            >
                        </li>
                        }
                    </ul>
                    }
                    <!---->
                </li>
                }
                <!---->
                @if (showReservationButton()) {
                <li>
                    <a routerLink="reservation" (click)="navigationClicked('reservation')">
                        {{'menu.reservationButton' | translate }}
                    </a>
                </li>
                }
            </ul>
        </div>
    </div>
</div>
