<solma-app-banner />

<!--==============================
      Contact Form Area
    ==============================-->
<section class="space">
    <div class="container">
        <div class="row gx-70">
            <div class="col-md-auto col-lg-5 wow fadeInUp" data-wow-delay="0.2s">
                <div class="info-box" data-overlay="theme" data-opacity="8">
                    <h3 class="sec-title text-uppercase">Otváracie hodiny</h3>
                    <div class="info-item">
                        <h4 class="info-title">Pondelok - Piatok</h4>
                        <p class="info-text">08:00 - 20:00</p>
                    </div>
                    <div class="info-item">
                        <h4 class="info-title">Sobota - Nedeľa</h4>
                        <p class="info-text">09:00 - 20:00</p>
                    </div>
                    <div class="info-item">
                        <p class="info-text">Len na objednávku</p>
                        <p class="info-text">Vstup vždy o celej hodine</p>
                    </div>
                </div>
            </div>
            <div class="col-md-auto col-lg-7 wow fadeInUp" data-wow-delay="0.3s">
                <solma-app-video [showDots]="false" />

                <div class="contact-table">
                    <div class="tr">
                        <div class="tb-col">
                            <span class="th">Adresa :</span>
                            <span class="td">Duklianskych hrdinov 47 (areál Fytopharma), Malacky</span>
                        </div>
                    </div>
                    <div class="tr">
                        <div class="tb-col">
                            <span class="th">email :</span>
                            <span class="td"
                                ><a href="mailto:solma@solma.sk" class="text-inherit">{{'solma@solma.sk'}}</a></span
                            >
                        </div>
                    </div>
                </div>
                <span class="h1">
                    <a href="tel:+421907358298" class="text-inherit"
                        ><i class="fal fa-headset me-3 text-theme"></i>+421 907 358 298</a
                    >
                </span>
            </div>
        </div>
    </div>
</section>
