<section class="space-top space-extra-bottom">
    <div class="container">
        <div class="title-area text-center wow fadeInUp" data-wow-delay="0.2s">
            <h2 class="sec-title">Špeciálne udalosti</h2>
            <div class="sec-shape"><img src="assets/img/shape/sec-shape-1.png" alt="shape" /></div>
        </div>
        @if (isLoading) {
        <div class="row">
            <div class="col text-center">
                <solma-loading-spinner></solma-loading-spinner>
            </div>
        </div>
        } @else {
        <div
            class="row vs-carousel wow fadeInUp"
            data-wow-delay="0.3s"
            data-slide-show="3"
            data-lg-slide-show="2"
            data-md-slide-show="2">
            @for (item of events; track item.id) {
            <div class="col-12">
                <div class="vs-blog blog-single">
                    <div class="blog-content">
                        <div class="blog-category">
                            <span>
                                @if (item.reserved_slot_count === 0) { Voľné } @else if (item.reserved_slot_count ===
                                item.max_capacity) { Obsadené } @else { Čiastočne obsadené }
                            </span>
                        </div>
                        <h2 class="blog-title">{{item.name}}</h2>
                        <div class="blog-meta">
                            <span>
                                <i class="fas fa-user"></i>
                                {{item.reserved_slot_count}} / {{item.max_capacity}}
                            </span>
                            <span
                                ><i class="fas fa-calendar-alt"></i>{{item.event_date | date}}, {{item.start_time |
                                timeFormat }} - {{item.end_time | timeFormat }}</span
                            >
                            <span><i class="far fa-euro-sign"></i>{{ item.price | currency }}</span>
                        </div>
                        <p class="blog-text">{{item.description}}</p>
                        @if (item.is_reservation_required && item.reserved_slot_count < item.max_capacity) {
                        <button (click)="wantBook(item)" class="vs-btn">{{ 'button.book' | translate }}</button>
                        }

                        @if (!item.is_reservation_required) {
                            <p>V prípade rezervácie nás priamo kontaktujte.</p>
                        }
                    </div>
                </div>
            </div>
            } @empty {
            <div class="col text-center">
                <h4 class="py-4">Aktuálne nemáme v ponuke žiadne špeciálne udalosti.</h4>
            </div>
            }

            <!----->

            @if (selectedEvent) {
            <div class="col">
                @if (selectedEvent) {
                <div class="row">
                    <div class="col-lg-5 col-xl-5">
                        <div class="team-about">
                            <span class="team-degi">Udalosť</span>
                            <h3 #serviceTitle class="team-name h1">{{selectedEvent.name}}</h3>
                            <div class="team-info">
                                <ul class="list-unstyled">
                                    <li>
                                        <span class="title">{{ 'calendarSlotDetail.date' | translate }}:</span>
                                        {{selectedEvent.event_date | date}}
                                    </li>
                                    <li>
                                        <span class="title">{{ 'calendarSlotDetail.time' | translate }}:</span>
                                        {{selectedEvent.start_time }} - {{selectedEvent.end_time }}
                                    </li>
                                    <li>
                                        <span class="title">{{ 'calendarSlotDetail.price' | translate }}:</span>
                                        {{selectedEvent.price | currency}}
                                    </li>
                                    <!---->

                                    <li>
                                        <span class="title">{{ 'calendarSlotDetail.capacity' | translate }}:</span>
                                        {{selectedEvent.reserved_slot_count}} / {{selectedEvent.max_capacity}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-xl-7">
                        <form [formGroup]="form" class="form-style6 pt-5">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div
                                        class="form-group"
                                        [ngClass]="{
                                                        error: form.controls['num_people'].touched && form.controls['num_people'].invalid
                                                    }">
                                        <label for="num_people"
                                            >{{ 'admin.reservation.properties.num_people' | translate }}*</label
                                        >
                                        <div class="input-group">
                                            <input
                                                formControlName="num_people"
                                                type="number"
                                                name="num_people"
                                                id="num_people"
                                                class="form-control" />
                                        </div>
                                        @if (form.controls['num_people'].touched && form.controls['num_people'].invalid)
                                        {
                                        <solma-input-error [errors]="form.controls['num_people'].errors" />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div
                                class="form-group"
                                [ngClass]="{
                                                    error:
                                                        form.controls['name'].touched && form.controls['name'].invalid
                                                }">
                                <label for="name">{{ 'admin.reservation.properties.name' | translate }}*</label>
                                <div class="input-group">
                                    <input
                                        formControlName="name"
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control" />
                                </div>
                                @if (form.controls['name'].touched && form.controls['name'].invalid) {
                                <solma-input-error [errors]="form.controls['name'].errors" />
                                }
                            </div>

                            <div
                                class="form-group"
                                [ngClass]="{
                                                    error:
                                                        form.controls['phone'].touched && form.controls['phone'].invalid
                                                }">
                                <label for="phone">{{ 'admin.reservation.properties.phone' | translate }}*</label>
                                <div class="input-group">
                                    <input
                                        formControlName="phone"
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        class="form-control" />
                                </div>
                                @if (form.controls['phone'].touched && form.controls['phone'].invalid) {
                                <solma-input-error [errors]="form.controls['phone'].errors" />
                                }
                            </div>

                            <div
                                class="form-group"
                                [ngClass]="{
                                                    error: form.controls['email'].touched && form.controls['email'].invalid
                                                }">
                                <label for="email">{{ 'admin.reservation.properties.email' | translate }}*</label>
                                <div class="input-group">
                                    <input
                                        formControlName="email"
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="form-control" />
                                </div>
                                @if (form.controls['email'].touched && form.controls['email'].invalid) {
                                <solma-input-error [errors]="form.controls['email'].errors" />
                                }
                            </div>

                            <div class="form-group">
                                <input formControlName="is_company" type="checkbox" id="is_company" name="is_company" />
                                <label for="is_company"
                                    >{{'admin.reservation.properties.is_company' | translate}}</label
                                >
                            </div>

                            @if (form.controls['is_company'].value) {
                            <!---->
                            @for (key of companyKeys; track $index) {
                            <div
                                class="form-group"
                                [ngClass]="{
                                                    error:
                                                        form.controls[key].touched && form.controls[key].invalid
                                                }">
                                <label [for]="key">{{ 'admin.reservation.properties.' + key | translate }}*</label>
                                <div class="input-group">
                                    <input
                                        [formControlName]="key"
                                        type="text"
                                        [name]="key"
                                        [id]="key"
                                        class="form-control" />
                                </div>
                                @if (form.controls[key].touched && form.controls[key].invalid) {
                                <solma-input-error [errors]="form.controls[key].errors" />
                                }
                            </div>
                            }
                            <!---->
                            }
                            <!---->
                            <div
                                class="form-group"
                                [ngClass]="{ error: (form.controls['confirm'].dirty || form.controls['confirm'].touched) && form.controls['confirm'].invalid }">
                                <input formControlName="confirm" type="checkbox" id="confirm" name="confirm" />
                                <label for="confirm"
                                    >{{'admin.reservation.properties.confirm' | translate}}
                                    <a
                                        target="_blank"
                                        download="Obchodné podmienky"
                                        href="assets/pdf/Obchodné podmienky.pdf"
                                        >{{'admin.reservation.properties.confirmLink' | translate}}</a
                                    >*
                                </label>
                                @if (form.controls['confirm'].touched && form.controls['confirm'].invalid) {
                                <solma-input-error [errors]="form.controls['confirm'].errors" />
                                }
                            </div>

                            <div class="product-about">
                                <p class="product-price">{{totalPrice | currency}}</p>
                            </div>

                            <div class="row">
                                <div class="col-lg-auto col-sm-12">
                                    @if (isBooking) {
                                    <solma-loading-spinner />
                                    } @else {
                                    <button class="vs-btn" type="button" (click)="book()">
                                        <i class="fal fa-pen"></i>
                                        {{ 'button.bookWithPayment' | translate }}
                                    </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                }
            </div>
            }
        </div>
        }
    </div>
</section>