import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BannerComponent } from '../banner';
import { VideoComponent } from '../video';

@Component({
    selector: 'solma-app-contact',
    templateUrl: 'component.html',
    styleUrl: 'component.scss',
    standalone: true,
    imports: [CommonModule, RouterModule, BannerComponent, VideoComponent],
})
export class ContactComponent {}
