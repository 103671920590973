<solma-app-banner />

<div class="position-relative">
    <div class="body-gradient-1"></div>
    <!--==============================
    About Area
    ==============================-->
    <section class="space-extra-top space-bottom">
        <div class="shape-mockup jump-reverse-img d-none d-xxl-block d-hd-none z-0" data-top="4%" data-left="-3%">
            <div class="curb-shape1"></div>
        </div>
        <div class="container">
            <div class="row gx-xl-0 pb-3">
                <div class="col-lg-6 col-xl-7 mb-40 mb-lg-0 wow fadeInUp" data-wow-delay="0.2s">
                    <div class="img-box1">
                        <img style="border-radius: 2rem" src="assets/img/about/about.png" alt="about" />
                    </div>
                </div>
                <div class="col-lg-6 col-xl-5 align-self-center wow fadeInUp" data-wow-delay="0.3s">
                    <h2 class="sec-title">Využitie a účinky soľnej jaskyne</h2>
                    <div class="media-style1">
                        <div class="circle-btn style3">
                            <a class="btn-icon"><i class="far fa-arrow-right"></i></a>
                            <div class="btn-text">
                                <svg viewBox="0 0 150 150">
                                    <text>
                                        <textPath href="#textPath"></textPath>
                                    </text>
                                </svg>
                            </div>
                        </div>
                        <div class="media-body">
                            <p class="media-text">
                                Účinky jaskyne sú veľmi široké a pre vstup nie je žiadna veková hranica.
                            </p>
                        </div>
                    </div>
                    <p class="about-text">
                        Účinky soľnej jaskyne na zdravie sú veľmi široké, od dýchacích ciest, cez kožné choroby až po
                        katar žalúdka. Účinky vplývajú už na deti po prvom týždni života až po dôchodcov - nie je tu
                        žiadna veková hranica (či už spodná alebo horná). Nižšie sa dočítate o hlavných zdravotných
                        účinkoch, ktoré Vám naša soľná jaskyňa ponúka.
                    </p>
                </div>
            </div>
        </div>
    </section>
</div>

<!--==============================
    Service Area
    ==============================-->
<section class="bg-vs-secondary space-top space-extra-bottom">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-9 col-lg-7 col-xl-6 wow fadeInUp" data-wow-delay="0.2s">
                <div class="title-area">
                    <h2 class="sec-title4">Účinky</h2>
                </div>
            </div>
        </div>
        <div class="row">
            @for (item of effects; track $index) {
            <div class="col-lg-3">
                <ng-container *ngTemplateOutlet="effect; context: { $implicit: item }" />
            </div>
            }
        </div>
    </div>
</section>

<!--==============================
    Feature Area
    ==============================-->
<section class="space-top space-extra-bottom bg-gradient-1 z-index-common">
    <div class="container">
        <div class="title-area text-center wow fadeInUp" data-wow-delay="0.2s">
            <h2 class="sec-title">Kto by nemal chodiť do soľnej jaskyne</h2>
            <div class="sec-shape"><img src="assets/img/shape/sec-shape-1.png" alt="shape" /></div>
        </div>
        <div class="row vs-carousel wow fadeInUp" data-wow-delay="0.3s" data-slide-show="3" data-md-slide-show="2">
            @for (item of warnings; track $index) {
            <div class="col-lg-4 col-xl-4">
                <ng-container *ngTemplateOutlet="warning; context: {$implicit: item}" />
            </div>
            }
        </div>
    </div>
</section>

<solma-app-gallery-section />

<ng-template #warning let-data>
    <div class="feature-style2">
        <h3 class="feature-title h4">{{data.title}}</h3>
        <div class="arrow-shape">
            <i class="arrow"></i><i class="arrow"></i><i class="arrow"></i><i class="arrow"></i>
        </div>
        <p class="feature-text">{{data.text}}</p>
    </div>
</ng-template>

<ng-template #effect let-data>
    <div class="service-style4">
        <span class="service-icon"><img src="assets/img/icons/sr-6-1.svg" alt="icon" /></span>
        <div class="service-content">
            <h3 class="service-title">
                <a class="text-inherit">{{data}}</a>
            </h3>
        </div>
    </div>
</ng-template>
