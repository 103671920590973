import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'solma-not-found',
    templateUrl: 'component.html',
    standalone: true,
    imports: [TranslateModule, RouterLink],
})
export class PageNotFoundComponent {}
