@for (toast of toastService.toasts.reverse(); track toast) {
<ngb-toast [class]="toast.classname" [delay]="5000" (hidden)="toastService.remove(toast)">
    <div class="toast-content">
        <span class="toast-text">{{ toast.text }}</span>
        <button class="btn toast-icon text-light p-0" (click)="toastService.remove(toast)">
            <i class="far fa-times"></i>
        </button>
    </div>
</ngb-toast>
}
