import { Injectable } from '@angular/core';

export interface Toast {
    text: string;
    classname?: string;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
    toasts: Toast[] = [];

    showSuccess(text: string) {
        this.show({
            text,
            classname: 'bg-success text-light',
        });
    }

    showError(text: string) {
        this.show({
            text,
            classname: 'bg-danger text-light',
        });
    }

    remove(toast: Toast) {
        this.toasts = this.toasts.filter((t) => t !== toast);
    }

    clear() {
        this.toasts = [];
    }

    private show(toast: Toast) {
        this.toasts.push(toast);
    }
}
