import { CommonModule } from '@angular/common';
import { AfterContentInit, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment, PreloaderComponent } from '@shared';
import { BannerComponent } from '../banner';
import { VideoComponent } from '../video';

declare function initCarousel(): void;

interface Service {
    title: string;
    link: string;
    text: string;
    reverse: boolean;
}

interface PricePlan {
    title: string;
    subTitle: string;
    price: string | number;
    link: string;
    buttonLabel: string;
}

@Component({
    selector: 'solma-app-home',
    templateUrl: 'component.html',
    styleUrl: 'component.scss',
    imports: [CommonModule, RouterModule, BannerComponent, PreloaderComponent, VideoComponent],
    standalone: true,
})
export class HomeComponent implements OnInit, AfterContentInit {
    private readonly changeDetector = inject(ChangeDetectorRef);
    private readonly translateService = inject(TranslateService);
    private readonly seasonTicketId = environment.seasonTicketId;

    //protected readonly searvices$ = inject(ServiceService).get();
    //protected readonly seasonTicketVariants$ = inject(SeasonTicketVariantService).get();

    protected showPreloader = true;

    protected readonly leftServices: Array<Service> = [
        {
            title: 'Účinky',
            text: 'Soľná jaskyňa pomáha na rôzne zdravotné problémy',
            link: 'effects',
            reverse: true,
        },
        {
            title: 'Deti',
            text: 'Vhodné pre deti po prvom týždni od narodenia',
            link: 'effects',
            reverse: true,
        },
        {
            title: 'Otváracie hodiny',
            text: 'PO-PIA - 08:00 - 20:00 <br /> SO-NE - 09:00 - 20:00',
            link: 'reservation',
            reverse: true,
        },
    ];

    protected readonly rightServices: Array<Service> = [
        {
            title: 'Rezervácie',
            text: 'Možné online alebo telefonicky',
            link: 'reservation',
            reverse: false,
        },
        {
            title: 'Cenník',
            text: 'Prenájom celej jaskyne a individuálne vstupy',
            link: 'price-plan',
            reverse: false,
        },
        {
            title: 'Parkovanie',
            text: 'Bezproblémové parkovanie priamo pri Soľnej jaskyni',
            link: 'contact',
            reverse: false,
        },
    ];

    protected readonly calatogPrices: Array<PricePlan> = [
        {
            title: 'Prenájom',
            subTitle: 'max 8 ľudí',
            price: 49,
            link: '/reservation',
            buttonLabel: this.translateService.instant('button.book'),
        },
        {
            title: 'Individuálny vstup s deťmi',
            subTitle: '1 osoba',
            price: '10/7',
            link: '/reservation',
            buttonLabel: this.translateService.instant('button.book'),
        },
        {
            title: 'Individuálny vstup len pre dospelých',
            subTitle: '1 osoba',
            price: 10,
            link: '/reservation',
            buttonLabel: this.translateService.instant('button.book'),
        },
        {
            title: 'Permanentka',
            subTitle: '5 prenájmov',
            price: 200,
            link: '/buy-season-ticket/2',
            buttonLabel: this.translateService.instant('button.buy'),
        },
        {
            title: 'Permanentka',
            subTitle: '10 individuálnych vstupov',
            price: 80,
            link: `/buy-season-ticket/${this.seasonTicketId}`,
            buttonLabel: this.translateService.instant('button.buy'),
        },
    ];

    ngOnInit(): void {
        initCarousel();
    }

    ngAfterContentInit(): void {
        this.showPreloader = false;
        this.changeDetector.detectChanges();
    }
}
