<solma-app-banner />

<div class="position-relative">
    <div class="body-gradient-1"></div>
    <!--==============================
    About Area
    ==============================-->
    <section class="space-extra-top space-bottom">
        <div class="shape-mockup jump-reverse-img d-none d-xxl-block d-hd-none z-0" data-top="4%" data-left="-3%">
            <div class="curb-shape1"></div>
        </div>
        <div class="container">
            @for (item of sections; track $index) {
            <ng-container *ngTemplateOutlet="section; context: { $implicit: item }" />
            }
        </div>
    </section>
</div>

<solma-app-gallery-section />

<ng-template #section let-data>
    <div class="row gx-xl-0 pb-3 mb-3" [ngClass]="{'flex-row-reverse': !data.imgLeft}">
        <div class="col-lg-6 col-xl-7 mb-40 mb-lg-0 wow fadeInUp" data-wow-delay="0.2s">
            <div class="img-box1">
                <img
                    [ngClass]="{'float-end': !data.imgLeft}"
                    style="border-radius: 2rem"
                    src="{{data.img}}"
                    alt="about" />
            </div>
        </div>
        <div class="col-lg-6 col-xl-5 align-self-center wow fadeInUp" data-wow-delay="0.3s">
            <h2 class="sec-title">{{data.title}}</h2>
            <div class="media-style1">
                <div class="circle-btn style3">
                    <a class="btn-icon"><i class="far fa-arrow-right"></i></a>
                    <div class="btn-text">
                        <svg viewBox="0 0 150 150">
                            <text>
                                <textPath href="#textPath"></textPath>
                            </text>
                        </svg>
                    </div>
                </div>
                <div class="media-body">
                    <p class="media-text">{{data.subTitle}}</p>
                </div>
            </div>
            <p class="about-text">
                {{data.text}} @if (data.link) {
                <a [routerLink]="data.link.link">{{data.link.text}}.</a>
                }
            </p>
        </div>
    </div>
</ng-template>
