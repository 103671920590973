<!--==============================
    Appointment Area
    ==============================-->
    <section class="bg-light-3 space h-100">
        <div class="container">
            <div class="row gx-60">
                <div class="col wow fadeInUp" data-wow-delay="0.3s">
                    <div class="row">
                        <div class="col align-self-center mb-5">
                            <div class="row mb-4 justify-content-between text-center">
                                <div class="col">
                                    <h2 class="h3 mt-n2">{{ 'admin.verifySeasonTicket.title' | translate }}</h2>
                                </div>
                            </div>
    
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-sm-12">
                                    @if (errorMessage) {
                                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                        {{ errorMessage }}
                                       
                                        <button
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="alert"
                                            aria-label="Close"></button>
                                    </div>
                                    }
                                    @if (successMessage) {
                                        <div class="alert alert-success alert-dismissible fade show" role="alert">
                                            {{ successMessage }}
                                           
                                            <button
                                                type="button"
                                                class="btn-close"
                                                data-bs-dismiss="alert"
                                                aria-label="Close"></button>
                                        </div>
                                        }
                                    <div
                                        class="widget widget_search form-group"
                                        [ngClass]="{
                                        error: control.touched && control.invalid
                                    }">
                                        <form class="search-form">
                                            <input [formControl]="control" type="text" placeholder="Zadajte kód..." />
                                            <button [disabled]="isLoading" (click)="verify()" type="button">
                                                @if (isLoading) {
                                                <solma-loading-spinner />
                                                } @else {
                                                <i class="far fa-search"></i>
                                                }
                                            </button>
                                        </form>
                                        @if (control.touched && control.invalid) {
                                        <solma-input-error [errors]="control.errors" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    