<!--==============================
    Header Area
    ==============================-->
<header class="vs-header header-layout1">
    <div class="sticky-wrap">
        <div class="sticky-active">
            <div class="container">
                <div class="row justify-content-between align-items-center gx-60">
                    <div class="col">
                        <div class="header-logo">
                            <a routerLink="/"><img src="assets/img/logo.png" alt="logo" /></a>
                        </div>
                    </div>
                    <div class="col-auto">
                        <nav class="main-menu menu-style1 d-none d-lg-block">
                            <ul>
                                @for (item of links(); track $index) {
                                <li
                                    [ngClass]="{
                                    'menu-item-has-children': !!item.children?.length
                                }">
                                    <a (click)="scrollTop()" [routerLink]="[item.link]"
                                        >{{'menu.links.' + item.label |translate}}</a
                                    >
                                    @if (item.children?.length) {
                                    <ul class="sub-menu">
                                        @for (subItem of item.children; track $index) {
                                        <li>
                                            <a [routerLink]="[subItem.link]"
                                                >{{'menu.links.' + subItem.label | translate}}</a
                                            >
                                        </li>
                                        }
                                    </ul>
                                    }
                                </li>
                                }
                            </ul>
                        </nav>
                    </div>
                    <div class="col-auto">
                        <div class="header-icons">
                            @if (showReservationButton()) {
                            <a routerLink="reservation" class="vs-btn style2 d-none d-lg-inline-block">
                                {{'menu.reservationButton' | translate}}
                            </a>
                            }
                            <button
                                class="vs-menu-toggle d-inline-block d-lg-none"
                                (click)="toggleMobileMenu()"
                                type="button">
                                <i class="fal fa-bars"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

@if (showMobile) {
<solma-mobile-menu
    [links]="links()"
    [showReservationButton]="showReservationButton()"
    (closeClick)="toggleMobileMenu()"
    (routeClick)="scrollTop()" />
}
