import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationLink } from '../../types';
import { scrollToTop } from '../../utils';
import { MobileMenuComponent } from '../mobile-menu';

@Component({
    selector: 'solma-navigation-bar',
    templateUrl: 'component.html',
    styleUrl: 'component.scss',
    imports: [RouterLink, MobileMenuComponent, TranslateModule, NgClass],
    standalone: true,
})
export class NavigationBarComponent {
    protected showMobile = false;

    public readonly links = input<NavigationLink[]>([]);
    public readonly showReservationButton = input<boolean>(true);

    toggleMobileMenu() {
        this.showMobile = !this.showMobile;
    }

    scrollTop() {
        scrollToTop();
    }
}
