import { Injectable } from '@angular/core';
import { DiscountCode, DiscountCodeRequest, DiscountCodeValidation, ModelBaseService } from '@shared';

@Injectable({ providedIn: 'root' })
export class DiscountCodeService extends ModelBaseService<DiscountCode, DiscountCodeRequest> {
    protected override modelUrl = 'discount-code';

    public verify(code: string) {
        return this.http.post<DiscountCodeValidation>(`${this.api}/${this.modelUrl}/verify`, { code });
    }
}
