import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Observer, Subscription } from 'rxjs';

@Component({
    template: '',
})
export abstract class BaseComponent {
    protected readonly destroyRef = inject(DestroyRef);

    protected subscribe<T>(observable: Observable<T>, callback: Partial<Observer<T>>): Subscription {
        return observable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(callback);
    }
}
