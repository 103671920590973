import { Component, input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'solma-input-error',
    standalone: true,
    imports: [TranslateModule],
    template: ` @for (error of errors(); track error[0]) { {{ 'error.' + error[0] | translate : error[1] }} } `,
})
export class InputErrorComponent {
    errors = input.required<Array<[string, any]>, ValidationErrors | null>({
        transform: (value: ValidationErrors | null) => Object.entries(value || {}),
    });
}
