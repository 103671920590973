import { Injectable } from '@angular/core';
import { BaseService } from '@shared';

@Injectable({ providedIn: 'root' })
export class PaymentService extends BaseService {
    eventReservationPaymentNotification(id: string) {
        return this.sendPaymentNotification(id, 'event-reservation');
    }

    reservationPaymentNotification(id: string) {
        return this.sendPaymentNotification(id, 'reservation');
    }

    seasonTicketPaymentNotification(id: string) {
        return this.sendPaymentNotification(id, 'season-ticket');
    }

    private sendPaymentNotification(id: string, model: 'season-ticket' | 'reservation' | 'event-reservation') {
        return this.http.get<{ id: string; order_number: string; state: string }>(
            `${this.api}/${model}/payment-notification`,
            {
                params: {
                    id,
                },
            }
        );
    }
}
