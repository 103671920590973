import { Injectable } from '@angular/core';
import { BaseService, ReservationType, SeasonTicketRequest, SeasonTicketValidation } from '@shared';

@Injectable({ providedIn: 'root' })
export class SeasonTicketService extends BaseService {
    protected modelUrl = 'season-ticket';

    public verify(code: string, type?: ReservationType) {
        return this.http.post<SeasonTicketValidation>(`${this.api}/${this.modelUrl}/verify`, { code, type });
    }

    public buy(request: SeasonTicketRequest) {
        return this.http.post<{ gw_url: string } | { error: boolean; message: string }>(
            `${this.api}/season-ticket/buy`,
            request
        );
    }
}
