import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { VideoComponent } from '../video';
import { GallerySectionComponent } from './section';

@Component({
    selector: 'solma-app-gallery',
    templateUrl: 'component.html',
    standalone: true,
    imports: [CommonModule, GallerySectionComponent, VideoComponent],
})
export class GalleryComponent {
    private myScriptElement!: HTMLScriptElement;

    ngOnInit(): void {
        this.myScriptElement = document.createElement('script');
        this.myScriptElement.src = 'assets/js/filter-active.js';
        document.body.appendChild(this.myScriptElement);
    }
}
