import { Injectable } from '@angular/core';
import { BaseService, SeasonTicketVariant } from '@shared';

@Injectable({ providedIn: 'root' })
export class SeasonTicketVariantService extends BaseService {
    get() {
        return this.http.get<SeasonTicketVariant[]>(`${this.api}/season-ticket-variant/display`);
    }

    getById(id: number) {
        return this.http.get<SeasonTicketVariant>(`${this.api}/season-ticket-variant/${id}`);
    }
}
