import { ChangeDetectorRef, Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { BaseComponent, NavigationBarComponent, PreloaderComponent, scrollToTop, ToastsComponent } from '@shared';
import { filter } from 'rxjs';
import { navigationLinks } from './app.routes';
import { FooterComponent } from './components';

@Component({
    selector: 'solma-app-root',
    standalone: true,
    imports: [RouterOutlet, PreloaderComponent, NavigationBarComponent, FooterComponent, ToastsComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends BaseComponent implements OnInit {
    private readonly changeDetector = inject(ChangeDetectorRef);
    private readonly router = inject(Router);

    protected readonly links = navigationLinks;
    public title = 'solma-fe';

    protected showPreloader = true;

    ngOnInit(): void {
        this.subscribe(this.router.events.pipe(filter((event) => event instanceof NavigationEnd)), {
            next: (_) => {
                scrollToTop();
            },
        });
    }

    ngAfterContentInit(): void {
        this.showPreloader = false;
        this.changeDetector.detectChanges();
    }
}
