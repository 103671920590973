import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    BaseComponent,
    Event,
    EventReservation,
    InputErrorComponent,
    LoadingSpinnerComponent,
    TimeFormatPipe,
    ToastService,
} from '@shared';
import { finalize } from 'rxjs';
import { EventReservationService, EventService } from '../../services';

@Component({
    selector: 'solma-app-events',
    templateUrl: 'component.html',
    styleUrl: 'component.scss',
    standalone: true,
    imports: [
        CommonModule,
        InputErrorComponent,
        DatePipe,
        CurrencyPipe,
        LoadingSpinnerComponent,
        TimeFormatPipe,
        TranslateModule,
        ReactiveFormsModule,
    ],
    providers: [DatePipe],
})
export class EventsComponent extends BaseComponent implements OnInit {
    protected readonly eventService = inject(EventService);
    protected readonly eventReservationService = inject(EventReservationService);
    protected readonly toastService = inject(ToastService);
    protected readonly translateService = inject(TranslateService);

    protected isLoading = false;
    protected isBooking = false;
    protected events: Array<Event> = [];
    protected selectedEvent: Event | null = null;
    protected form!: FormGroup;
    protected totalPrice = 0;
    protected readonly companyKeys: Array<keyof EventReservation> = [
        'ico',
        'dic',
        'icdph',
        'address',
        'country',
        'city',
        'postal_code',
    ];

    ngOnInit(): void {
        this.isLoading = true;
        this.setForm();
        this.subscribe(this.eventService.get().pipe(finalize(() => (this.isLoading = false))), {
            next: (data) => (this.events = data),
        });
    }

    wantBook(event: Event) {
        this.selectedEvent = event;

        document.querySelector('h3')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.resetForm(event);
    }

    book() {
        if (!this.selectedEvent) return;
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const value = this.form.getRawValue();
        this.isBooking = true;
        this.subscribe(
            this.eventReservationService
                .createReservation({
                    ...value,
                    event_id: this.selectedEvent.id,
                })
                .pipe(finalize(() => (this.isBooking = false))),
            {
                next: (data) => {
                    if ('gw_url' in data) {
                        this.selectedEvent = null;
                        this.resetForm();
                        window.location.href = data.gw_url;
                    } else if ('error' in data) {
                        if (data.error) {
                            this.toastService.showError(this.translateService.instant(data.message));
                            return;
                        }
                    }
                },
            }
        );
    }

    private resetForm(event?: Event) {
        this.form.reset();

        if (event) {
            this.form.removeControl('num_people');
            this.form.addControl(
                'num_people',
                new FormControl<number | null>(null, {
                    updateOn: 'blur',
                    validators: [
                        Validators.required,
                        Validators.min(1),
                        Validators.max(event.max_capacity - event.reserved_slot_count),
                    ],
                })
            );
        }
    }

    private setForm() {
        this.form = new FormGroup(
            {
                num_people: new FormControl<number | null>(null, [Validators.required, Validators.min(1)]),
                name: new FormControl(null, [Validators.required]),
                phone: new FormControl(null, [Validators.required]),
                email: new FormControl(null, [Validators.required, Validators.email]),
                is_company: new FormControl<boolean>(false, {
                    nonNullable: true,
                    validators: [Validators.required],
                }),
                ico: new FormControl(null),
                dic: new FormControl(null),
                icdph: new FormControl(null),
                address: new FormControl(null),
                country: new FormControl(null),
                city: new FormControl(null),
                postal_code: new FormControl(null),
                confirm: new FormControl<boolean>(false, {
                    nonNullable: true,
                    validators: [Validators.requiredTrue],
                }),
            },
            {
                validators: (control: AbstractControl) => {
                    const value = control.getRawValue();
                    if (value.is_company) {
                        this.companyKeys.forEach((key) => {
                            if (!value[key]) {
                                control.get(key)?.setErrors({ required: true });
                            }
                        });
                    }
                    return null;
                },
            }
        );

        this.subscribe(this.form.controls['is_company'].valueChanges, {
            next: (is_company: boolean) => {
                this.companyKeys.forEach((key) => {
                    if (!is_company) {
                        this.form.controls[key].reset();
                    }
                });
            },
        });

        this.subscribe(this.form.valueChanges, {
            next: (value) => {
                //calculate price
                this.totalPrice = 0;
                if (this.selectedEvent) {
                    this.totalPrice = this.selectedEvent.price * value.num_people;
                }
            },
        });
    }
}
