<!--==============================
			Footer Area
	==============================-->
<footer class="footer-wrapper footer-layout3" data-bg-src="assets/img/bg/footer-bg-3-1.jpg">
    <div class="widget-area">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-md-6 col-xxl-auto">
                    <div class="widget footer-widget">
                        <div class="footer-logo mb-4">
                            <img style="max-width: 200px" src="assets/img/logo.png" alt="logo" />
                        </div>
                        <p class="footer-info">
                            <i class="fal fa-map-marker-alt text-theme me-2"></i> Duklianskych hrdinov 47 (areál
                            Fytopharma), Malacky <br />
                            <a href="tel:+421907358298" class="text-inherit"
                                ><i class="far fa-phone-alt text-theme me-2"></i>+421 907 358 298</a
                            >
                            <br />
                            <a class="text-inherit" href="mailto:solma@solma.sk"
                                ><i class="fal fa-envelope text-theme me-2"></i>{{'solma@solma.sk'}}</a
                            >
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-xxl-auto">
                    <div class="widget widget_nav_menu footer-widget">
                        <h3 class="widget_title">Otváracie hodiny</h3>
                        <p class="footer-time">Pondelok - Piatok: <span class="time">08:00 - 20:00</span></p>
                        <p class="footer-time">Sobota - Nedeľa: <span class="time">09:00 - 20:00</span></p>
                    </div>
                </div>
                <div class="col-md-6 col-xxl-auto">
                    <div class="widget widget_nav_menu footer-widget">
                        <h3 class="widget_title">Odkazy</h3>
                        <div class="menu-all-pages-container footer-menu">
                            <ul class="menu">
                                @for (item of links; track $index) {
                                <li>
                                    <a [routerLink]="[item.link]">{{'menu.links.' + item.label | translate}}</a>
                                </li>
                                }
                                <li>
                                    <a
                                        target="_blank"
                                        download="Obchodné podmienky"
                                        href="assets/pdf/Obchodné podmienky.pdf"
                                        >{{'menu.links.termsAndConditions' | translate}}</a
                                    >
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        download="Odstúpenie od zmluvy"
                                        href="assets/pdf/Odstúpenie od zmluvy.pdf"
                                        >{{'menu.links.contractWithdrawal' | translate}}</a
                                    >
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        download="Reklamačný poriadok"
                                        href="assets/pdf/Reklamačný poriadok.pdf"
                                        >{{'menu.links.complaints' | translate}}</a
                                    >
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        download="ARS"
                                        href="assets/pdf/ARS - Poučenie o práve spotrebiteľa podať obchodníkovi žiadosť o nápravu.pdf"
                                        >{{'menu.links.ars' | translate}}</a
                                    >
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        download="Zásady ochrany osobných údajov"
                                        href="assets/pdf/Zásady ochrany osobných údajov.pdf"
                                        >{{'menu.links.gdpr' | translate}}</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xxl-auto">
                    <div class="widget footer-widget">
                        <a target="_blank" href="https://www.instagram.com/solna_jaskyna_malacky"
                            ><h4 class="widget_title">instagram</h4></a
                        >
                        <div class="sidebar-gallery column-4">
                            <div class="gallery-thumb">
                                <a target="_blank" href="https://www.instagram.com/solna_jaskyna_malacky">
                                    <img src="assets/img/ig/ig1.jpg" alt="Gallery Image" class="w-100" />
                                </a>
                            </div>
                            <div class="gallery-thumb">
                                <a target="_blank" href="https://www.instagram.com/solna_jaskyna_malacky"
                                    ><img src="assets/img/ig/ig2.jpg" alt="Gallery Image" class="w-100"
                                /></a>
                            </div>
                            <div class="gallery-thumb">
                                <a target="_blank" href="https://www.instagram.com/solna_jaskyna_malacky"
                                    ><img src="assets/img/ig/ig3.jpg" alt="Gallery Image" class="w-100"
                                /></a>
                            </div>
                            <div class="gallery-thumb">
                                <a target="_blank" href="https://www.instagram.com/solna_jaskyna_malacky"
                                    ><img src="assets/img/ig/ig4.jpg" alt="Gallery Image" class="w-100"
                                /></a>
                            </div>
                            <div class="gallery-thumb">
                                <a target="_blank" href="https://www.instagram.com/solna_jaskyna_malacky"
                                    ><img src="assets/img/ig/ig5.jpg" alt="Gallery Image" class="w-100"
                                /></a>
                            </div>
                            <div class="gallery-thumb">
                                <a target="_blank" href="https://www.instagram.com/solna_jaskyna_malacky"
                                    ><img src="assets/img/ig/ig6.jpg" alt="Gallery Image" class="w-100"
                                /></a>
                            </div>
                            <div class="gallery-thumb">
                                <a target="_blank" href="https://www.instagram.com/solna_jaskyna_malacky"
                                    ><img src="assets/img/ig/ig7.jpg" alt="Gallery Image" class="w-100"
                                /></a>
                            </div>
                            <div class="gallery-thumb">
                                <a target="_blank" href="https://www.instagram.com/solna_jaskyna_malacky"
                                    ><img src="assets/img/ig/ig8.jpg" alt="Gallery Image" class="w-100"
                                /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-wrap">
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-md-auto text-center">
                    <p class="copyright-text">
                        Copyright <i class="fal fa-copyright"></i> {{currentYear}} <a [routerLink]="['']">solma.sk</a>. Všetky
                        práva vyhradené.
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>
