import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BannerComponent } from '../banner';
import { GallerySectionComponent } from '../gallery';

@Component({
    selector: 'solma-app-effects',
    templateUrl: 'component.html',
    styleUrl: 'component.scss',
    imports: [CommonModule, BannerComponent, GallerySectionComponent],
    standalone: true,
})
export class EffectsComponent {
    protected readonly effects: Array<string> = [
        'Astma',
        'Dýchacie cesty',
        'Chronický zápal pľúc a priedušiek',
        'Chronický zápal hrdla, nosa, hrtanu a chronickej nádchy',
        'Kožné choroby a ekzémy',
        'Chorôb srdca a ciev',
        'Alergie rôznych druhov',
        'Zvýšený krvný tlak',
        'Žaludočných vredov',
        'Kataru žalúdka',
        'Bronchitída',
        'Sinsuitída',
        'Znížená funkcia štítnej žľazy',
        'Odbúranie stresu',
        'Poruchy spánku',
    ];

    protected readonly warnings: Array<{ title: string; text: string }> = [
        {
            title: 'Ľudia s infekčným ochorením',
            text: '(vstup vyslovene zakázaný)',
        },
        {
            title: 'Akútna tuberkulóza',
            text: '(vstup vyslovene zakázaný)',
        },
        {
            title: 'Zvýšená funkcia štítnej žľazy',
            text: '(pred prvým vstupom povinná konzultácia so svojim lekárom)',
        },
        {
            title: 'Onkologické ochorenia',
            text: '(pred prvým vstupom povinná konzultácia so svojim lekárom)',
        },
        {
            title: 'Choroby obličiek',
            text: '(pred prvým vstupom povinná konzultácia so svojim lekárom)',
        },
    ];

    private myScriptElement!: HTMLScriptElement;

    ngOnInit(): void {
        this.myScriptElement = document.createElement('script');
        this.myScriptElement.src = 'assets/js/filter-active.js';
        document.body.appendChild(this.myScriptElement);
    }
}
