@if(showPreloader) {
<solma-preloader></solma-preloader>
}

<solma-app-banner />

<!--==============================
    Service Area
    ==============================-->
<section class="space">
    <div class="service-inner1">
        <div class="shape-mockup jump d-none d-xxl-block" data-top="-10%" data-right="0">
            <img src="assets/img/hero/hero-leaf-5.png" alt="shape" />
        </div>
        <div class="container-xl">
            <div class="row justify-content-between align-items-center">
                <div class="col-md-6 col-lg-5 col-xxl-auto">
                    @for (item of leftServices; track $index) {
                    <ng-container *ngTemplateOutlet="service; context: {$implicit: item}" />
                    }
                </div>
                <div class="col col-xxl-auto text-center d-none d-lg-block">
                    <img
                        style="padding: 100px 0px 100px 0px"
                        src="assets/img/solna_lampa.png"
                        alt="shape"
                        class="mt-n4" />
                </div>
                <div class="col-md-6 col-lg-5 col-xxl-auto">
                    @for (item of rightServices; track $index) {
                    <ng-container *ngTemplateOutlet="service; context: {$implicit: item}" />
                    }
                </div>
            </div>
        </div>
    </div>
</section>

<!--==============================
    Catalog
    ==============================-->
<section class="space-top space-extra-bottom" style="background-image: url('assets/img/bg/testi-bg-2-1.jpg')">
    <div class="container">
        <div class="title-area text-center">
            <h2 class="sec-title">Cenník</h2>
        </div>
        <div class="pb-1px"></div>
        <div class="row gx-50">
            @for (item of calatogPrices; track $index) {
            <div class="col-xxl-3 col-sm-12 col-lg-3">
                <ng-container *ngTemplateOutlet="catalog; context: {$implicit: item}" />
            </div>
            }
        </div>
    </div>
</section>

<!--==============================
    Video section
    ==============================-->
<section class="space">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-auto wow fadeInUp" data-wow-delay="0.2s">
                <solma-app-video />
            </div>
        </div>
    </div>
</section>

<!--==============================
    Gallery Area
    ==============================-->
<section class="position-relative space-extra-bottom">
    <div class="gallery-shape1"></div>
    <div class="container-fluid">
        <div
            class="row gallery-slider1 vs-carousel"
            data-slide-show="3"
            data-center-mode="true"
            data-xl-center-mode="true"
            data-ml-center-mode="true"
            data-lg-center-mode="true"
            data-md-center-mode="true"
            data-center-padding="100px"
            data-adaptive-height="true"
            data-center-padding="477px"
            data-xl-center-padding="320px"
            data-ml-center-padding="200px"
            data-lg-center-padding="150px"
            data-md-center-padding="80px">
            <div class="col">
                <div class="gallery-style2">
                    <div class="gallery-img"><img src="assets/img/gallery/gallery1.png" alt="gallery" /></div>
                </div>
            </div>
            <div class="col">
                <div class="gallery-style2">
                    <div class="gallery-img"><img src="assets/img/gallery/gallery2.png" alt="gallery" /></div>
                </div>
            </div>
            <div class="col">
                <div class="gallery-style2">
                    <div class="gallery-img"><img src="assets/img/gallery/gallery3.png" alt="gallery" /></div>
                </div>
            </div>
            <div class="col">
                <div class="gallery-style2">
                    <div class="gallery-img"><img src="assets/img/gallery/gallery4.png" alt="gallery" /></div>
                </div>
            </div>
            <div class="col">
                <div class="gallery-style2">
                    <div class="gallery-img"><img src="assets/img/gallery/gallery5.png" alt="gallery" /></div>
                </div>
            </div>
            <div class="col">
                <div class="gallery-style2">
                    <div class="gallery-img"><img src="assets/img/gallery/gallery6.png" alt="gallery" /></div>
                </div>
            </div>
            <div class="col">
                <div class="gallery-style2">
                    <div class="gallery-img"><img src="assets/img/gallery/gallery7.png" alt="gallery" /></div>
                </div>
            </div>
            <div class="col">
                <div class="gallery-style2">
                    <div class="gallery-img"><img src="assets/img/gallery/gallery8.png" alt="gallery" /></div>
                </div>
            </div>
            <div class="col">
                <div class="gallery-style2">
                    <div class="gallery-img"><img src="assets/img/gallery/gallery9.png" alt="gallery" /></div>
                </div>
            </div>
            <div class="col">
                <div class="gallery-style2">
                    <div class="gallery-img"><img src="assets/img/gallery/gallery10.png" alt="gallery" /></div>
                </div>
            </div>
        </div>
        <div class="arrows-style1">
            <button data-slick-prev=".gallery-slider1"><i class="arrow"></i>Pedošlá</button>
            <button data-slick-next=".gallery-slider1"><i class="arrow"></i>Ďalšia</button>
        </div>
    </div>
</section>

<ng-template #service let-data>
    <div class="service-style1" [ngClass]="{'reverse': data.reverse}">
        <div [routerLink]="[data.link]" class="vs-icon"><img src="assets/img/icon/sr-i-1-4.png" alt="icon" /></div>
        <div class="service-content">
            <h3 class="service-title">
                <a [routerLink]="[data.link]" class="text-inherit">{{data.title}}</a>
            </h3>
            <p class="service-text" [innerHTML]="data.text"></p>
        </div>
    </div>
</ng-template>

<ng-template #catalog let-data>
    <div class="package-style1 layout2">
        <div class="package-top">
            <div class="row">
                <div class="col-12 package-left">
                    <p class="package-price">{{data.price}}<span class="currency">€</span></p>
                    <p class="package-duration">{{data.subTitle}}</p>
                </div>
                <h3 class="col-12 package-name">{{data.title}}</h3>
            </div>
        </div>
        <div class="package-shape"><img src="assets/img/shape/price-shape-3.png" alt="shape" /></div>
        <div class="package-btn catalogBtn">
            <a [routerLink]="data.link" class="vs-btn style3">{{data.buttonLabel}}</a>
        </div>
    </div>
</ng-template>
