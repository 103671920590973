import { Component, input } from '@angular/core';

@Component({
    selector: 'solma-app-video',
    templateUrl: 'component.html',
    standalone: true,
})
export class VideoComponent {
    showDots = input<boolean>(true);
}
