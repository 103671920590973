import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BannerComponent } from '../banner';
import { GallerySectionComponent } from '../gallery';

interface AboutUsSection {
    title: string;
    subTitle: string;
    text: string;
    img: string;
    imgLeft: boolean;
    link?: {
        text: string;
        link: string;
    };
}

@Component({
    selector: 'solma-app-about-us',
    templateUrl: 'component.html',
    imports: [CommonModule, RouterLink, BannerComponent, GallerySectionComponent],
    standalone: true,
})
export class AboutUsComponent {
    protected readonly sections: Array<AboutUsSection> = [
        {
            title: 'O soľnej jaskyni',
            subTitle: 'Pobyt v jaskyni po dobu 45 minút sa rovná 2-3 dňom pri mori',
            text: `Soľná jaskyňa je inhalačná miestnosť, ktorá má pozitívne účinky na naše zdravie a myseľ. Skladá sa zo soľných kameňov a posypovej soli - vďaka tomu je vzduch v jaskyni prirovnávaný tomu pri mori. 45-minútový pobyt v Soľnej jaskyni sa rovná 2-3 dňom pri mori. Soľná jaskyňa je vhodné od najmenších detí až po dôchodcov. Do jaskyne by nemali chodiť ľudia s určitým typom ochorení - viac info `,
            img: 'assets/img/about/about.png',
            imgLeft: true,
            link: {
                link: '/effects',
                text: 'tu',
            },
        },
        {
            title: 'Štandardy našej jaskyne',
            subTitle: 'Naša soľná jaskyňa je vybudovaná z najkvalitnejších materiálov',
            text: `Pri budovaní našej jaskyne sme si dali za hlavný cieľ, aby spĺňala tie najvyššie štandardy. Jaskyňa je zložená z tých najkvalitnejších materiálov a vecí. Vďaka tomu v jaskyni nebudú vznikať plesne a ani sa vo vzduchu nebudú držať baktérie. Viac informácií, z čoho je jaskyňa zložená sa dočítate nižšie.`,
            img: 'assets/img/about/standards.png',
            imgLeft: false,
        },
        {
            title: 'Obvodové steny',
            subTitle: 'Po obvodových stenách sú soľné kamene a tehly z Pakistanu',
            text: `Obvodové steny sa skladajú zo soľných kameňov až z ďalekého Pakistanu. Soľné kamene z Pakistanu sú jednými z najpoužívanejších v soľných jaskyniach práve kvôli ich kvalite. Aj vďaka nim spĺňa vzduch v jaskyni tie najvyššie štandardy.`,
            img: 'assets/img/about/walls.png',
            imgLeft: true,
        },
        {
            title: 'Posypová soľ',
            subTitle: 'Na podlahe je vrstva posypovej soli z Poľska',
            text: `Po celej zemi v soľnej jaskyni sa nachádza 6-7cm vrstva soli z Poľska. Túto soľ pravidelne dopĺňame podľa potreby, aby účinky našej soľnej jaskyne boli čo najväčšie.`,
            img: 'assets/img/about/salt.png',
            imgLeft: false,
        },
        {
            title: 'Vodopády',
            subTitle: 'Pre správnu funkčnosť jaskyne je potrebný vodopád, my máme hneď dva',
            text: `V našej jaskyni sa nachádzajú 2 vodopády, ktoré zabezpečujú, aby bola v jaskyni potrebná vlhkosť vzduchu (cca 60%) a takisto udržiava čistý vzduch v jaskyni vďaka roztoku soli.`,
            img: 'assets/img/about/waterfalls.png',
            imgLeft: true,
        },
        {
            title: 'Soľný generátor',
            subTitle: 'Prístroj, bez ktorého by sa nezaobišla žiadna správna soľná jaskyňa',
            text: `Soľný generátor je prístroj, ktorý do vzduchu púšťa špeciálny soľný roztok (Solanku) z vody z Mŕtveho mora (vyrobené v Izraeli). Je to takzvaná suchá terapia inhalácie, kedy častice soli, ktoré majú veľkosť do 5 mikrometrov, prenikajú hlboko do dýchacích ciest a kože. Inhalácia patrí k účinnému spôsobu uvoľnenia dutín, prekrveniu a zvlhčeniu slizníc.`,
            img: 'assets/img/about/salt-generator.png',
            imgLeft: false,
        },
        {
            title: 'Germicídna lampa',
            subTitle: 'Spoľahlivý odstraňovač baktérií',
            text: `Aj vďaka tejto lampe sa vo vzduchu neudržia baktérie a teda ak jaskyňu navštívia chorľaví ľudia, tak germicídna lampa je jednou z vecí, ktorá tieto baktérie zlikviduje. Taktiež udržuje čistotu vzduchu v jaskyni.`,
            img: 'assets/img/about/germicidlamp.png',
            imgLeft: true,
        },
        {
            title: 'Podlahové kúrenie',
            subTitle: 'V zime to ocenia deti, ktoré sa hrajú s posypovou soľou',
            text: `Ako návštevníci to síce nevidíte, ale v zime to určite pocítite. Podlahové kúrenie v Soľnej jaskyni má hlavný význam v tom, že nahrieva posypovú soľ na zemi v jaskyni a vďaka tomu sa do ovzdušia dostávajú výpary aj z tejto soli. Druhou výhodou je, že je to v zime príjemné pre deti, ktoré sa hrajú na zemi s hračkami.`,
            img: 'assets/img/about/about.png',
            imgLeft: false,
        },
        {
            title: 'Klimatizácia a vzduchotechnika',
            subTitle: 'Žiadne plesne a udržaná požadovaná teplota v jaskyni',
            text: `Ide takisto o veľmi dôležitú súčasť vybavenia soľnej jaskyne. Klimatizácia s podlahovým kúrením udržuje požadovanú teplotu (21-22°C) v soľnej jaskyni, aby mala tie správne účinky. Vzduchotechniku máme precízne vybudovanú kvôli tomu, aby v jaskyni nevznikali plesne. Potrubia privádzajú do jaskyne čerstvý vzduch, ale takisto odsávajú preč „starý vzduch“ z jaskyne, ale aj z medzi priestoru, ktorý ako návštevníci nevidíte (medzi priestor je medzi soľnými kameňmi, stropom a obvodovými stenami). Vďaka tomu nevznikajú plesne ani v tomto medzi priestore.`,
            img: 'assets/img/about/aircondition.png',
            imgLeft: true,
        },
    ];

    private myScriptElement!: HTMLScriptElement;
    private myScriptElement2!: HTMLScriptElement;

    ngOnInit(): void {
        this.myScriptElement = document.createElement('script');
        this.myScriptElement.src = 'assets/js/carousel.js';
        document.body.appendChild(this.myScriptElement);

        this.myScriptElement2 = document.createElement('script');
        this.myScriptElement2.src = 'assets/js/filter-active.js';
        document.body.appendChild(this.myScriptElement2);
    }
}
