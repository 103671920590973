<!--==============================
     Preloader
  ==============================-->
<div class="preloader">
    <div class="preloader-inner">
        <div class="loader"></div>
    </div>
</div>
<svg viewBox="0 0 150 150" class="svg-hidden">
    <path id="textPath" d="M 0,75 a 75,75 0 1,1 0,1 z"></path>
</svg>
