<!--==============================
    Gallery Area
    ==============================-->
<section class="outer-wrap1 pt-20 pb-20">
    <div class="container-fluid px-0">
        <div class="row gx-20 gy-gx filter-active">
            @for (item of galleryItems; track $index) {
            <div class="col-md-6 col-xxl-auto filter-item">
                <div class="gallery-style1">
                    <div class="gallery-img">
                        <img [src]="item" alt="Gallery Image" class="w-100" />
                    </div>
                    <div class="gallery-shape" data-overlay="white" data-opacity="9"></div>
                    <div class="gallery-content">
                        <a [href]="item" class="gallery-btn popup-image"><i class="fal fa-plus"></i></a>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
</section>
