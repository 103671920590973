import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'timeFormat',
    standalone: true,
})
export class TimeFormatPipe implements PipeTransform {
    transform(value: string | null, format: string = 'HH:mm'): string {
        if (!value) return '';
        return moment(value, 'HH:mm:ssZ').format(format);
    }
}
