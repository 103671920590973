import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, scrollToTop } from '@shared';
import { finalize, Observable } from 'rxjs';

@Component({
    template: '',
})
export abstract class PaymentVerificationComponent extends BaseComponent implements OnInit {
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);

    protected abstract paymentVerification: (id: string) => Observable<{
        id: string;
        order_number: string;
        state: string;
    }>;

    protected isLoading = true;
    protected result = false;

    ngOnInit() {
        scrollToTop();
        const id = this.route.snapshot.queryParamMap.get('id');

        if (!id) {
            this.router.navigate(['/reservation']);
            return;
        }

        this.subscribe(this.paymentVerification(id).pipe(finalize(() => (this.isLoading = false))), {
            next: (data) => {
                if (data.state === 'PAID') {
                    this.result = true;
                } else if (data.state === 'CREATED' || data.state === 'CANCELED' || data.state === 'TIMEOUTED') {
                    this.result = false;
                }
                setTimeout(() => {
                    this.router.navigate(['/reservation']);
                }, 5000);
            },
        });
    }
}
