<!--==============================
    Call To Action
    ==============================-->
<section class="space" style="background-image: url('assets/img/bg/cta-bg-2-1.png')">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-auto wow fadeInUp" data-wow-delay="0.2s">
                <solma-app-video />
            </div>
        </div>
    </div>
</section>

<solma-app-gallery-section />
