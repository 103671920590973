import { Injectable } from '@angular/core';
import { BaseService, Reservation, ReservationRequest } from '@shared';

@Injectable({ providedIn: 'root' })
export class CalendarService extends BaseService {
    getCalendarData(start: string, end: string) {
        return this.http.get<Array<any>>(`${this.api}/calendar`, {
            params: {
                start,
                end,
            },
        });
    }

    createReservation(reservation: ReservationRequest) {
        return this.http.post<{ gw_url: string } | { error: boolean; message: string } | Reservation>(
            `${this.api}/reservation/book`,
            reservation
        );
    }

    verifyPayment(id: string) {
        return this.http.get<{ id: string; order_number: string; state: string }>(`${this.api}/payment-notification`, {
            params: {
                id,
            },
        });
    }
}
