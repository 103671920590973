import { Injectable } from '@angular/core';
import { BaseService, EventRequest } from '@shared';

@Injectable({ providedIn: 'root' })
export class EventReservationService extends BaseService {
    createReservation(reservation: EventRequest) {
        return this.http.post<{ gw_url: string } | { error: boolean; message: string }>(
            `${this.api}/event-reservation/book`,
            reservation
        );
    }
}
