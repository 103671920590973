import { map } from 'rxjs';
import { Pagination } from '../types';
import { BaseService } from './base.service';

export abstract class ModelBaseService<TEntity, TRequest = TEntity> extends BaseService {
    protected abstract modelUrl: string;

    getAll(url = `${this.modelUrl}`) {
        return this.http.get<Pagination<TEntity>>(`${this.api}/${url}`).pipe(
            map((data) => ({
                ...data,
                links: data.links.filter((_, index, links) => index !== 0 && index !== links.length - 1),
            }))
        );
    }

    get(id: number) {
        return this.http.get<TEntity>(`${this.api}/${this.modelUrl}/${id}`);
    }

    add(model: TRequest) {
        return this.http.post<TEntity>(`${this.api}/${this.modelUrl}`, model);
    }

    update(id: number, model: TRequest) {
        return this.http.put<TEntity>(`${this.api}/${this.modelUrl}/${id}`, model);
    }

    delete(id: number) {
        return this.http.delete(`${this.api}/${this.modelUrl}/${id}`);
    }
}
