import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationLink } from '@shared';

@Component({
    selector: 'solma-mobile-menu',
    templateUrl: 'component.html',
    imports: [RouterLink, TranslateModule, NgClass],
    standalone: true,
})
export class MobileMenuComponent {
    public readonly links = input<NavigationLink[]>([]);
    public readonly showReservationButton = input<boolean>(true);

    public closeClick = output();
    public routeClick = output<string>();

    close() {
        this.closeClick.emit();
    }

    navigationClicked(route: string) {
        this.routeClick.emit(route);
        this.close();
    }
}
