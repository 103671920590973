import { Component, inject } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services';

@Component({
    selector: 'solma-toasts',
    templateUrl: 'component.html',
    styleUrl: 'component.scss',
    standalone: true,
    imports: [NgbToastModule],
    host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
})
export class ToastsComponent {
    toastService = inject(ToastService);
}
