import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Service } from '@shared';

@Pipe({
    name: 'serviceList',
    standalone: true,
})
export class ServiceListPipe implements PipeTransform {
    private readonly translateService = inject(TranslateService);
    transform(services: Array<Service>, ...args: any[]): string {
        return services.map((s) => this.translateService.instant(`enum.reservationType.${s.type}`)).join(', ');
    }
}
