import { Injectable } from '@angular/core';
import { BaseService, Event } from '@shared';

@Injectable({ providedIn: 'root' })
export class EventService extends BaseService {
    get() {
        return this.http.get<Event[]>(`${this.api}/event/display`);
    }

    getById(id: number) {
        return this.http.get<Event>(`${this.api}/event/${id}`);
    }
}
