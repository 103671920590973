import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core/index.js';
import skLocale from '@fullcalendar/core/locales/sk';
import timeGridPlugin from '@fullcalendar/timegrid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    BaseComponent,
    DiscountCode,
    InputErrorComponent,
    LoadingSpinnerComponent,
    Reservation,
    scrollToTop,
    SeasonTicket,
    Service,
    ToastService,
} from '@shared';
import moment from 'moment';
import { finalize, map, of } from 'rxjs';
import { CalendarService, DiscountCodeService, SeasonTicketService } from '../../services';

@Component({
    selector: 'solma-app-calendar',
    templateUrl: 'component.html',
    styleUrl: 'component.scss',
    standalone: true,
    imports: [
        FullCalendarModule,
        RouterLink,
        TranslateModule,
        LoadingSpinnerComponent,
        CommonModule,
        InputErrorComponent,
        ReactiveFormsModule,
    ],
    providers: [DatePipe],
})
export class CalendarComponent extends BaseComponent implements OnInit {
    private readonly service = inject(CalendarService);
    private readonly seasonTicketService = inject(SeasonTicketService);
    private readonly discountCodeService = inject(DiscountCodeService);
    private readonly datePipe = inject(DatePipe);
    private readonly translateService = inject(TranslateService);
    private readonly changeDetector = inject(ChangeDetectorRef);
    private readonly toastService = inject(ToastService);

    protected isLoading = false;
    protected showSuccessMessage = false;

    protected basePrice = 0;
    protected totalPrice = 0;

    protected form!: FormGroup;
    protected readonly companyKeys: Array<keyof Reservation> = [
        'ico',
        'dic',
        'icdph',
        'address',
        'country',
        'city',
        'postal_code',
    ];

    protected isSeasonTicketApplied = false;
    protected isDiscountCodeApplied = false;

    protected isSeasonTicketVerifying = false;
    protected isDiscountCodeVerifying = false;

    protected seasonTicketApplied: SeasonTicket | null = null;
    protected discountCodeApplied: DiscountCode | null = null;

    protected discountCodeCtrl = new FormControl(null, {
        updateOn: 'blur',
        asyncValidators: [
            (control: AbstractControl) => {
                if (!control.value) return of(null);

                this.isDiscountCodeVerifying = true;
                return this.discountCodeService.verify(control.value).pipe(
                    map((result) => {
                        if (!result.error) {
                            this.onDiscountCodeApplied(result.model);
                            return null;
                        }
                        return { [result.message]: true };
                    }),
                    finalize(() => {
                        this.isDiscountCodeVerifying = false;
                    })
                );
            },
        ],
    });

    protected seasonTicketCodeCtrl = new FormControl(null, {
        updateOn: 'blur',
        asyncValidators: [
            (control: AbstractControl) => {
                if (!control.value || !this.selectedEvent) return of(null);

                this.isSeasonTicketVerifying = true;
                return this.seasonTicketService.verify(control.value, this.selectedEvent.service.type).pipe(
                    map((result) => {
                        if (!result.error) {
                            this.onSeasonTicketApplied(result.model);
                            return null;
                        }
                        return { [result.message]: true };
                    }),
                    finalize(() => {
                        this.isSeasonTicketVerifying = false;
                    })
                );
            },
        ],
    });

    protected selectedEvent: {
        service: Service;
        disabled_price: number | null;
        adult_price: number;
        child_price: number | null;
        start: string;
        end: string;
        occupied_capacity: number | null;
        remaining_capacity: number | null;
    } | null = null;

    protected calendarOptions!: CalendarOptions;

    ngOnInit() {
        this.calendarOptions = {
            plugins: [timeGridPlugin],
            themeSystem: 'bootstrap5',
            initialView: window.innerWidth < 1300 ? 'timeGridDay' : 'timeGridWeek',
            validRange: {
                start:
                    window.innerWidth < 1300 ? moment(new Date()).toDate() : moment(new Date()).startOf('W').toDate(),
            },
            height: 'auto',
            contentHeight: 'auto',
            expandRows: true,
            displayEventTime: false,
            slotMinTime: '06:00:00',
            slotMaxTime: '22:00:00',
            slotDuration: '01:00:00',
            allDaySlot: false,
            locales: [skLocale],
            locale: 'sk',
            scrollTimeReset: false,
            editable: true,
            selectable: true,
            events: (fetchInfo, successCallback, failureCallback) => {
                this.isLoading = true;
                this.changeDetector.detectChanges();
                this.subscribe(
                    this.service.getCalendarData(fetchInfo.startStr.split('+')[0], fetchInfo.endStr.split('+')[0]).pipe(
                        map((data) =>
                            data.map((e) => ({ ...e, start: e.start.split('+')[0], end: e.end.split('+')[0] }))
                        ),
                        finalize(() => {
                            this.isLoading = false;
                        })
                    ),
                    {
                        next: (data) => {
                            successCallback(
                                data.map((e) => ({
                                    interactive: this.isSelectable(this.checkCapacityType(e), e),
                                    editable: this.isSelectable(this.checkCapacityType(e), e),
                                    title: this.setTitle(this.checkCapacityType(e), e?.service?.type),
                                    start: e.start,
                                    end: e.end,
                                    textColor: this.setTextColor(this.checkCapacityType(e)),
                                    backgroundColor: this.setBackgroundColor(this.checkCapacityType(e)),
                                    borderColor: this.setBorderColor(this.checkCapacityType(e)),
                                    extendedProps: {
                                        blocked: e.blocked,
                                        adult_price: e.adult_price,
                                        disabled_price: e.disabled_price,
                                        child_price: e.child_price,
                                        id: e.id,
                                        service: e.service,
                                        remaining_capacity: e.remaining_capacity,
                                        occupied_capacity:
                                            !e.blocked && e.service.capacity
                                                ? e.service.capacity - e.remaining_capacity
                                                : null,
                                    },
                                }))
                            );
                        },
                        error: (err) => failureCallback(err),
                    }
                );
            },
            eventClick: (event) => {
                if (event.event.extendedProps['blocked'] || event.event.extendedProps['remaining_capacity'] === 0)
                    return;
                if (moment(new Date()).add(15, 'minutes').isAfter(moment(event.event.start))) return;

                this.selectedEvent = {
                    start: event.event.startStr,
                    end: event.event.endStr,
                    child_price: event.event.extendedProps['child_price'],
                    disabled_price: event.event.extendedProps['disabled_price'],
                    occupied_capacity: event.event.extendedProps['occupied_capacity'],
                    adult_price: event.event.extendedProps['adult_price'],
                    remaining_capacity: event.event.extendedProps['remaining_capacity'],
                    service: event.event.extendedProps['service'],
                };

                document.querySelector('h3')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                this.resetForm();
            },
        };
        this.setForm();
    }

    verifyDiscountCode() {
        const code = this.discountCodeCtrl.value;
        if (!code) return;

        this.isDiscountCodeVerifying = true;
        this.subscribe(
            this.discountCodeService.verify(code).pipe(
                finalize(() => {
                    this.isDiscountCodeVerifying = false;
                })
            ),
            {
                next: (data) => {
                    if (!data.error) {
                        this.onDiscountCodeApplied(data.model);
                    }
                },
            }
        );
    }

    verifySeasonTicket() {
        const code = this.seasonTicketCodeCtrl.value;
        if (!code || !this.selectedEvent) return;

        this.isSeasonTicketVerifying = true;
        this.subscribe(
            this.seasonTicketService.verify(code, this.selectedEvent.service.type).pipe(
                finalize(() => {
                    this.isSeasonTicketVerifying = false;
                })
            ),
            {
                next: (data) => {
                    if (!data.error) {
                        this.onSeasonTicketApplied(data.model);
                    }
                },
            }
        );
    }

    save() {
        if (!this.selectedEvent) return;
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const value = this.form.getRawValue();
        this.isLoading = true;
        this.subscribe(
            this.service
                .createReservation({
                    ...value,
                    season_ticket_code: this.isSeasonTicketApplied ? this.seasonTicketCodeCtrl.value : null,
                    discount_code: this.isDiscountCodeApplied ? this.discountCodeCtrl.value : null,
                    voucher_code: null,
                    reservation_date: this.datePipe.transform(this.selectedEvent.start, 'YYYY-MM-dd'),
                    start_time: this.datePipe.transform(this.selectedEvent.start, 'HH:mm'),
                    end_time: this.datePipe.transform(this.selectedEvent.end, 'HH:mm'),
                    adult_price: this.selectedEvent.adult_price,
                    service_id: this.selectedEvent.service.id,
                })
                .pipe(finalize(() => (this.isLoading = false))),
            {
                next: (data) => {
                    if ('error' in data) {
                        if (data.error) {
                            this.toastService.showError(this.translateService.instant(data.message));
                            return;
                        }
                    }

                    this.selectedEvent = null;
                    this.resetForm();

                    this.seasonTicketCodeCtrl.reset(null, {
                        emitEvent: false,
                    });

                    this.discountCodeCtrl.reset(null, {
                        emitEvent: false,
                    });

                    if ('gw_url' in data) {
                        window.location.href = data.gw_url;
                    } else {
                        this.showSuccessMessage = true;
                        scrollToTop();
                        setTimeout(() => {
                            window.location.reload();
                        }, 5000);
                    }
                },
            }
        );
    }

    private resetForm() {
        this.form.reset();
        this.seasonTicketCodeCtrl.reset();
        this.discountCodeCtrl.reset();
        this.seasonTicketCodeCtrl.enable();
        this.discountCodeCtrl.enable();

        this.isSeasonTicketApplied = false;
        this.isDiscountCodeApplied = false;
        this.isSeasonTicketVerifying = false;
        this.isDiscountCodeVerifying = false;
        this.discountCodeApplied = null;
        this.seasonTicketApplied = null;

        ['num_adults', 'num_disabled', 'num_children'].forEach((key) => this.form.removeControl(key));

        if (
            this.selectedEvent &&
            this.selectedEvent.remaining_capacity !== null &&
            this.selectedEvent.service.capacity !== null
        ) {
            this.form.addControl(
                'num_adults',
                new FormControl<number>(1, {
                    nonNullable: true,
                    updateOn: 'blur',
                    validators: [
                        Validators.required,
                        Validators.min(0),
                        Validators.max(this.selectedEvent.remaining_capacity),
                    ],
                })
            );

            this.form.addControl(
                'num_disabled',
                new FormControl<number>(0, {
                    nonNullable: true,
                    updateOn: 'blur',
                    validators: [
                        Validators.required,
                        Validators.min(0),
                        Validators.max(this.selectedEvent.remaining_capacity),
                    ],
                })
            );
        }

        if (
            this.selectedEvent &&
            this.selectedEvent.service.capacity !== null &&
            this.selectedEvent.remaining_capacity !== null &&
            this.selectedEvent.child_price !== null
        ) {
            this.form.addControl(
                'num_children',
                new FormControl<number>(1, {
                    nonNullable: true,
                    updateOn: 'blur',
                    validators: [
                        Validators.required,
                        Validators.min(0),
                        Validators.max(this.selectedEvent.remaining_capacity - 1),
                    ],
                })
            );
        }
    }

    private setForm() {
        this.form = new FormGroup(
            {
                name: new FormControl(null, [Validators.required]),
                phone: new FormControl(null, [Validators.required]),
                email: new FormControl(null, [Validators.required, Validators.email]),
                is_company: new FormControl<boolean>(false, {
                    nonNullable: true,
                    validators: [Validators.required],
                }),
                ico: new FormControl(null),
                dic: new FormControl(null),
                icdph: new FormControl(null),
                address: new FormControl(null),
                country: new FormControl(null),
                city: new FormControl(null),
                postal_code: new FormControl(null),
                confirm: new FormControl<boolean>(false, {
                    nonNullable: true,
                    validators: [Validators.requiredTrue],
                }),
            },
            {
                validators: (control: AbstractControl) => {
                    const value = control.getRawValue();
                    if (
                        this.selectedEvent &&
                        this.selectedEvent.remaining_capacity != null &&
                        this.selectedEvent.service.capacity
                    ) {
                        if (value.num_disabled === 0 && value.num_adults === 0) {
                            ['num_adults', 'num_disabled'].forEach((key) => {
                                control.get(key)?.setErrors({
                                    min: { min: 1 },
                                });
                            });
                        } else {
                            ['num_adults', 'num_disabled']
                                .filter((key) => this.form.controls[key] && this.form.controls[key].value >= 0)
                                .forEach((key) => {
                                    if (this.form.controls[key] && this.form.controls[key].hasError('min')) {
                                        const err = this.form.controls[key].errors;
                                        this.form.controls[key].setErrors(
                                            err
                                                ? Object.keys(err).reduce<null | Record<string, any>>((obj, curr) => {
                                                      if (curr === 'min') {
                                                          return obj;
                                                      }
                                                      if (obj === null) {
                                                          return {
                                                              [curr]: err[curr],
                                                          };
                                                      }
                                                      return {
                                                          ...obj,
                                                          [curr]: err[curr],
                                                      };
                                                  }, null)
                                                : null
                                        );
                                    }
                                });
                        }

                        if (
                            (value.num_children != null &&
                                value.num_disabled == null &&
                                value.num_children + value.num_adults > this.selectedEvent.remaining_capacity) ||
                            (value.num_disabled != null &&
                                value.num_children == null &&
                                value.num_disabled + value.num_adults > this.selectedEvent.remaining_capacity) ||
                            (value.num_children != null &&
                                value.num_disabled != null &&
                                value.num_children + value.num_disabled + value.num_adults >
                                    this.selectedEvent.remaining_capacity)
                        ) {
                            this.form.controls['num_adults'].setErrors({
                                sumMax: { max: this.selectedEvent.remaining_capacity },
                            });
                            if (value.num_children != null) {
                                this.form.controls['num_children'].setErrors({
                                    sumMax: { max: this.selectedEvent.remaining_capacity },
                                });
                            }
                            if (value.num_disabled != null) {
                                this.form.controls['num_disabled'].setErrors({
                                    sumMax: { max: this.selectedEvent.remaining_capacity },
                                });
                            }
                        } else {
                            if (
                                this.form.controls['num_adults'] &&
                                this.form.controls['num_adults'].hasError('sumMax')
                            ) {
                                this.form.controls['num_adults'].setErrors(null);
                            }

                            if (
                                this.form.controls['num_children'] &&
                                this.form.controls['num_children'].hasError('sumMax')
                            ) {
                                this.form.controls['num_children'].setErrors(null);
                            }

                            if (
                                this.form.controls['num_disabled'] &&
                                this.form.controls['num_disabled'].hasError('sumMax')
                            ) {
                                this.form.controls['num_disabled'].setErrors(null);
                            }
                        }
                    }

                    if (value.num_adults && value.season_ticket_apply_count) {
                        if (value.season_ticket_apply_count > value.num_adults) {
                            this.form.controls['season_ticket_apply_count'].setErrors({
                                max: {
                                    max: value.num_adults,
                                },
                            });
                        }
                    }

                    if (value.is_company) {
                        this.companyKeys.forEach((key) => {
                            if (!value[key]) {
                                this.form.controls[key].setErrors({ required: true });
                            }
                        });
                    }
                    return null;
                },
            }
        );

        this.subscribe(this.form.controls['is_company'].valueChanges, {
            next: (is_company: boolean) => {
                this.companyKeys.forEach((key) => {
                    if (!is_company) {
                        this.form.controls[key].reset();
                    }
                });
            },
        });

        this.subscribe(this.form.valueChanges, {
            next: (value) => {
                //calculate price

                if (this.selectedEvent) {
                    if (this.selectedEvent.service.capacity) {
                        this.basePrice =
                            this.selectedEvent.adult_price * value.num_adults +
                            (this.selectedEvent.disabled_price
                                ? this.selectedEvent.disabled_price * value.num_disabled
                                : 0) +
                            (this.selectedEvent.child_price ? this.selectedEvent.child_price * value.num_children : 0);
                    } else {
                        this.basePrice = this.selectedEvent.adult_price;
                    }
                }

                this.totalPrice = this.basePrice;

                if (this.isSeasonTicketApplied && this.selectedEvent) {
                    this.totalPrice -= this.selectedEvent.adult_price * (value.season_ticket_apply_count ?? 0);
                }

                if (this.isDiscountCodeApplied && this.discountCodeApplied) {
                    this.totalPrice -= this.discountCodeApplied.percentage * this.totalPrice;
                }

                if (this.totalPrice < 0) {
                    this.totalPrice = 0;
                }
            },
        });
    }

    private onDiscountCodeApplied(model: DiscountCode) {
        this.discountCodeApplied = model;
        this.isDiscountCodeApplied = true;
        this.discountCodeCtrl.disable();
        this.discountCodeCtrl.setErrors(null);

        this.totalPrice -= model.percentage * this.totalPrice;
    }

    private onSeasonTicketApplied(model: SeasonTicket) {
        this.seasonTicketApplied = model;
        this.isSeasonTicketApplied = true;
        this.seasonTicketCodeCtrl.disable();
        this.seasonTicketCodeCtrl.setErrors(null);

        this.form.addControl(
            'season_ticket_apply_count',
            new FormControl<number>(1, {
                nonNullable: true,
                updateOn: 'blur',
                validators: [
                    Validators.required,
                    Validators.min(1),
                    Validators.max(model.max_number_of_uses - model.used_count),
                ],
            })
        );
    }

    private checkCapacityType(data: {
        service: Service;
        remaining_capacity: number;
        blocked: boolean;
    }): 'FREE' | 'FULLY_RESERVED' | 'PARTIAL' | 'BLOCKED' {
        if (data.blocked) return 'BLOCKED';
        if (data.remaining_capacity <= 0) return 'FULLY_RESERVED';
        if (data.remaining_capacity < data.service.capacity) return 'PARTIAL';
        return 'FREE';
    }

    private setTitle(type: 'FREE' | 'FULLY_RESERVED' | 'PARTIAL' | 'BLOCKED', serviceType?: Service['type']): string {
        switch (type) {
            case 'PARTIAL':
            case 'FREE':
                return `${this.translateService.instant(`enum.reservationType.${serviceType}`)}`;
            case 'BLOCKED':
                return this.translateService.instant('admin.calendar.closed');
            case 'FULLY_RESERVED':
            default:
                return this.translateService.instant('admin.calendar.fullyReserved');
        }
    }

    private isSelectable(type: 'FREE' | 'FULLY_RESERVED' | 'PARTIAL' | 'BLOCKED', event: { start: Date }): boolean {
        switch (type) {
            case 'PARTIAL':
            case 'FREE':
                return moment(new Date()).add(15, 'minutes').isBefore(moment(event.start));
            case 'BLOCKED':
            case 'FULLY_RESERVED':
            default:
                return false;
        }
    }

    private setTextColor(type: 'FREE' | 'FULLY_RESERVED' | 'PARTIAL' | 'BLOCKED') {
        if (type === 'PARTIAL') return '#000';
        return '#fff';
    }

    private setBackgroundColor(type: 'FREE' | 'FULLY_RESERVED' | 'PARTIAL' | 'BLOCKED') {
        switch (type) {
            case 'FREE':
                return '#157347';
            case 'FULLY_RESERVED':
                return '#dc3545';
            case 'PARTIAL':
                return '#ffc107';
            case 'BLOCKED':
            default:
                return '#5c636a';
        }
    }

    private setBorderColor(type: 'FREE' | 'FULLY_RESERVED' | 'PARTIAL' | 'BLOCKED') {
        switch (type) {
            case 'FREE':
                return '#146c43';
            case 'FULLY_RESERVED':
                return '#dc3545';
            case 'PARTIAL':
                return '#ffc107';
            case 'BLOCKED':
            default:
                return '#565e64';
        }
    }
}
