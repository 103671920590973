import { Routes } from '@angular/router';
import { NavigationLink, PageNotFoundComponent } from '@shared';
import {
    AboutUsComponent,
    BuySeasonTicketComponent,
    CalendarComponent,
    ContactComponent,
    EffectsComponent,
    EventReservationPaymentVerificationComponent,
    EventsComponent,
    GalleryComponent,
    HomeComponent,
    PricePlanComponent,
    ReservationPaymentVerificationComponent,
    SeasonTicketPaymentVerificationComponent,
    VerificationComponent,
} from './components';

export const navigationLinks: NavigationLink[] = [
    {
        label: 'aboutUs',
        link: 'about-us',
    },
    {
        label: 'effects',
        link: 'effects',
    },
    {
        label: 'pricePlan',
        link: 'price-plan',
    },
    {
        label: 'events',
        link: 'events',
    },
    {
        label: 'gallery',
        link: 'gallery',
    },
    {
        label: 'contact',
        link: 'contact',
    },
];

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'about-us',
        component: AboutUsComponent,
    },
    {
        path: 'effects',
        component: EffectsComponent,
    },
    {
        path: 'price-plan',
        component: PricePlanComponent,
    },
    {
        path: 'gallery',
        component: GalleryComponent,
    },
    {
        path: 'contact',
        component: ContactComponent,
    },
    {
        path: 'reservation',
        component: CalendarComponent,
    },
    {
        path: 'verification',
        component: VerificationComponent,
    },
    {
        path: 'buy-season-ticket/:variantId',
        component: BuySeasonTicketComponent,
    },
    {
        path: 'verify-event-reservation',
        component: EventReservationPaymentVerificationComponent,
    },
    {
        path: 'verify-reservation',
        component: ReservationPaymentVerificationComponent,
    },
    {
        path: 'verify-season-ticket',
        component: SeasonTicketPaymentVerificationComponent,
    },
    {
        path: 'events',
        component: EventsComponent,
    },
    { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];
