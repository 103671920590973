<!--==============================
    Error Area 
==============================-->
<section class="vs-error-wrapper space" data-bg-src="assets/img/bg/bg-error.jpg">
    <div class="container">
        <div class="error-content">
            <h1 class="error-number">4<span class="zero">0</span>4</h1>
            <h2 class="error-title">{{'pageNotFound.title' | translate}}</h2>
            <p class="error-text">{{'pageNotFound.text' | translate}}</p>
            <a routerLink="/" class="vs-btn style5">{{'pageNotFound.backBtn' | translate}}</a>
        </div>
    </div>
</section>
