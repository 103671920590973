<solma-app-banner />

<!--==============================
    Price Plan Area
    ==============================-->
<section class="space-top space-extra-bottom" style="background-image: url('assets/img/bg/price-bg-3-1.png')">
    <div class="container">
        <div class="title-area text-center wow fadeInUp" data-wow-delay="0.2s">
            <h2 class="sec-title">Cenník</h2>
            <div class="sec-shape"><img src="assets/img/shape/sec-shape-1.png" alt="shape" /></div>
        </div>
        <div
            class="row vs-carousel wow fadeInUp min-vh-100"
            data-wow-delay="0.3s"
            data-slide-show="3"
            data-lg-slide-show="2"
            data-md-slide-show="2">
            @for (item of pricePlanItems; track $index) {
            <div class="col-xl-4">
                <ng-container *ngTemplateOutlet="pricePlanItem; context: { $implicit: item }" />
            </div>
            }
        </div>
    </div>
</section>

<ng-template #pricePlanItem let-data>
    <div class="package-style1">
        <div class="package-top">
            <div class="package-left">
                <p class="package-price">
                    {{data.price}}@if(!data.hideCurrencySymbol){<span class="currency">€</span>}
                </p>
                <p class="package-duration">{{data.subTitle}}</p>
            </div>
            <h3 class="package-name text-end">{{data.title}}</h3>
        </div>
        <div class="package-shape"><img src="assets/img/shape/price-shape-2.png" alt="shape" /></div>
        <div class="package-list">
            <ul class="list-unstyled">
                @for (item of data.info; track $index) {
                <li style="padding-right: 30px">{{item}}</li>
                }
            </ul>
        </div>
        <div class="package-btn">
            <a [routerLink]="data.link" class="vs-btn style3">{{data.buttonLabel}}</a>
        </div>
    </div>
</ng-template>
