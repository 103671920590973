import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import localeSk from '@angular/common/locales/sk';
import {
    APP_INITIALIZER,
    ApplicationConfig,
    DEFAULT_CURRENCY_CODE,
    importProvidersFrom,
    LOCALE_ID,
    provideZoneChangeDetection,
} from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { routes } from './app.routes';

function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

function appInitFactory(translateService: TranslateService) {
    return () => {
        return translateService.use('sk');
    };
}

registerLocaleData(localeSk, 'sk');

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitFactory,
            deps: [TranslateService],
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'sk' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        provideHttpClient(),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideAnimationsAsync(),
        importProvidersFrom([
            TranslateModule.forRoot({
                defaultLanguage: 'sk',
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
        ]),
    ],
};
