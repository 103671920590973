<!--==============================
    Appointment Area
    ==============================-->
<section class="bg-light-3 space h-100">
    <div class="container">
        <div class="row gx-60">
            <div class="col wow fadeInUp" data-wow-delay="0.3s">
                @if (!variant && isLoading) {
                <div class="row">
                    <div class="col align-self-center mb-5">
                        <div class="row mb-4 justify-content-between text-center">
                            <div class="col">
                                <solma-loading-spinner />
                            </div>
                        </div>
                    </div>
                </div>
                }
                <!---->
                @if (variant) {
                <div class="row">
                    <div class="col-lg-5 col-xl-5">
                        <div class="team-about">
                            <span class="team-degi">{{'buySeasonTicket.title' | translate}}</span>
                            <h3 #serviceTitle class="team-name h1">{{'buySeasonTicket.subTitle' | translate}}</h3>
                            <div class="team-info">
                                <ul class="list-unstyled">
                                    <li>
                                        <span class="title">{{ 'buySeasonTicket.services' | translate }}:</span>
                                        {{ variant.services | serviceList }}
                                    </li>
                                    <li>
                                        <span class="title">{{ 'buySeasonTicket.price' | translate }}:</span>
                                        {{ variant.price | currency }}
                                    </li>
                                    <li>
                                        <span class="title"
                                            >{{ 'buySeasonTicket.max_number_of_uses' | translate }}:</span
                                        >
                                        {{ variant.max_number_of_uses }}
                                    </li>
                                    <li>
                                        <span class="title">{{ 'buySeasonTicket.validity_period' | translate }}:</span>
                                        {{ variant.validity_period }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-xl-7">
                        <form [formGroup]="form" class="form-style6">
                            <div
                                class="form-group"
                                [ngClass]="{
                                        error:
                                            form.controls['name'].touched && form.controls['name'].invalid
                                    }">
                                <label for="name">{{ 'admin.seasonTicket.properties.name' | translate }}*</label>
                                <div class="input-group">
                                    <input
                                        formControlName="name"
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control" />
                                </div>
                                @if (form.controls['name'].touched && form.controls['name'].invalid) {
                                <solma-input-error [errors]="form.controls['name'].errors" />
                                }
                            </div>

                            <div
                                class="form-group"
                                [ngClass]="{
                                        error:
                                            form.controls['phone'].touched && form.controls['phone'].invalid
                                    }">
                                <label for="phone">{{ 'admin.seasonTicket.properties.phone' | translate }}*</label>
                                <div class="input-group">
                                    <input
                                        formControlName="phone"
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        class="form-control" />
                                </div>
                                @if (form.controls['phone'].touched && form.controls['phone'].invalid) {
                                <solma-input-error [errors]="form.controls['phone'].errors" />
                                }
                            </div>

                            <div
                                class="form-group"
                                [ngClass]="{
                                        error: form.controls['email'].touched && form.controls['email'].invalid
                                    }">
                                <label for="email">{{ 'admin.seasonTicket.properties.email' | translate }}*</label>
                                <div class="input-group">
                                    <input
                                        formControlName="email"
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="form-control" />
                                </div>
                                @if (form.controls['email'].touched && form.controls['email'].invalid) {
                                <solma-input-error [errors]="form.controls['email'].errors" />
                                }
                            </div>

                            <div class="form-group">
                                <input formControlName="is_company" type="checkbox" id="is_company" name="is_company" />
                                <label for="is_company"
                                    >{{'admin.seasonTicket.properties.is_company' | translate}}</label
                                >
                            </div>

                            @if (form.controls['is_company'].value) {
                            <!---->
                            @for (key of companyKeys; track $index) {
                            <!---->
                            @let control = form.get(key); @if (control) {
                            <div
                                class="form-group"
                                [ngClass]="{
                                        error:
                                            control.touched && control.invalid
                                    }">
                                <label [for]="key">{{ 'admin.seasonTicket.properties.' + key | translate }}*</label>
                                <div class="input-group">
                                    <input
                                        [formControlName]="key"
                                        type="text"
                                        [name]="key"
                                        [id]="key"
                                        class="form-control" />
                                </div>
                                @if (control.touched && control.invalid) {
                                <solma-input-error [errors]="control.errors ?? null" />
                                }
                            </div>
                            }
                            <!---->
                            }
                            <!---->
                            }
                            <div
                                class="form-group"
                                [ngClass]="{ error: (form.controls['confirm'].dirty || form.controls['confirm'].touched) && form.controls['confirm'].invalid }">
                                <input formControlName="confirm" type="checkbox" id="confirm" name="confirm" />
                                <label for="confirm"
                                    >{{'admin.reservation.properties.confirm' | translate}}
                                    <a
                                        target="_blank"
                                        download="Obchodné podmienky"
                                        href="assets/pdf/Obchodné podmienky.pdf"
                                        >{{'admin.reservation.properties.confirmLink' | translate}}</a
                                    >*
                                </label>
                                @if (form.controls['confirm'].touched && form.controls['confirm'].invalid) {
                                <solma-input-error [errors]="form.controls['confirm'].errors" />
                                }
                            </div>

                            <div class="product-about">
                                <p class="product-price">{{variant.price | currency}}</p>
                            </div>

                            @if(isLoading) {
                            <solma-loading-spinner />
                            } @else {
                            <div class="row">
                                <div class="col-lg-auto col-sm-12">
                                    <button class="vs-btn" type="button" (click)="save()">
                                        <i class="fal fa-pen"></i>
                                        {{ 'button.buyWithPayment' | translate }}
                                    </button>
                                </div>
                            </div>
                            }
                        </form>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
</section>
