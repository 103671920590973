import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { navigationLinks } from '../../app.routes';

@Component({
    selector: 'solma-app-footer',
    templateUrl: 'component.html',
    styleUrl: 'component.scss',
    standalone: true,
    imports: [RouterModule, TranslateModule],
})
export class FooterComponent {
    public readonly links = navigationLinks;
    public readonly currentYear = new Date().getFullYear();
}
