<div class="img-box4" data-overlay="theme" data-opacity="4">
    <div class="img-1">
        <img src="assets/img/video/snimka4.PNG" alt="thumbnail" class="w-100" />
    </div>
    @if (showDots()) {
    <div class="img-2 jump"><img src="assets/img/shape/shape-dotted.png" alt="shape" /></div>
    <div class="img-3 jump"><img src="assets/img/shape/shape-dotted.png" alt="shape" /></div>
    }
    <a href="https://www.youtube.com/watch?v=n75lszCB_eg" class="watch-btn style2 popup-video">
        <span class="play-btn"><i class="fas fa-play"></i></span>
    </a>
</div>
