import { Component } from '@angular/core';

@Component({
    selector: 'solma-app-gallery-section',
    templateUrl: 'component.html',
    standalone: true,
})
export class GallerySectionComponent {
    protected readonly galleryItems: Array<string> = [
        'assets/img/gallery/gallery1.png',
        'assets/img/gallery/gallery2.png',
        'assets/img/gallery/gallery3.png',
        'assets/img/gallery/gallery4.png',
        'assets/img/gallery/gallery5.png',
        'assets/img/gallery/gallery6.png',
        'assets/img/gallery/gallery7.png',
        'assets/img/gallery/gallery8.png',
        'assets/img/gallery/gallery9.png',
        'assets/img/gallery/gallery10.png',
    ];
}
