import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@shared';
import { BannerComponent } from '../banner';

interface PricePlanItem {
    price: number | string;
    title: string;
    subTitle: string;
    info: Array<string>;
    hideCurrencySymbol?: boolean;
    buttonLabel: string;
    link: string;
}

@Component({
    selector: 'solma-app-price-plan',
    templateUrl: 'component.html',
    styleUrl: 'component.scss',
    standalone: true,
    imports: [CommonModule, RouterModule, BannerComponent],
})
export class PricePlanComponent {
    private readonly translateService = inject(TranslateService);
    private readonly seasonTicketId = environment.seasonTicketId;

    protected readonly pricePlanItems: Array<PricePlanItem> = [
        {
            title: 'Prenájom jaskyne',
            price: 49,
            subTitle: '',
            info: [
                'Vhodné pre rodiny s deťmi',
                'Celá jaskyňa len pre Vás a Vašich blízkych',
                'Maximálna kapacita 10 ľudí',
                'Vstupy cez týždeň v Utorok a Štvrtok od 08:00 - 18:00',
                'Vstupy cez víkend o 10,12,14,16 a 18:00',
                'Hračky v jaskyni zabezpečené',
            ],
            link: '/reservation',
            buttonLabel: this.translateService.instant('button.book'),
        },
        {
            title: 'Individuálny vstup s deťmi',
            price: '10/7',
            subTitle: '',
            info: [
                'Cena pre dospelú osobu je 10€, cena pre dieťa je 7€',
                'Vstup vhodný pre rodičov s deťmi, ktorým nevadí zdieľať priestor s druhými ľuďmi',
                'Maximálna kapacita 10 osôb',
                'Vstupy cez týždeň v Pondelok, Stredu a Piatok od 08:00 - 18:00',
                'Vstupy cez víkend o 11,13,15 a 17:00',
                'Hračky v jaskyni zabezpečené',
            ],
            link: '/reservation',
            buttonLabel: this.translateService.instant('button.book'),
        },
        {
            title: 'Individuálny vstup pre dospelých',
            price: 10,
            subTitle: '',
            info: [
                'Vstup od 15 rokov a vyššie',
                'Vstup vhodný pre dospelých ludí, ktorí si chcú v pohodlí vychutnať relaxačnú hudbu, no nevadí im zdielať priestor s druhými ľuďmi (napr. dôchodcovia alebo ľudia, ktorí chcú prísť bez detí)',
                'Maximálna kapacita 10 osôb',
                'Vstupy cez týždeň o 19:00',
                'Vstupy cez víkend o 9:00, a 19:00',
            ],
            link: '/reservation',
            buttonLabel: this.translateService.instant('button.book'),
        },
        {
            title: 'Permanentka individuálny vstup',
            price: 80,
            subTitle: '',
            info: [
                'Permanentka na 10 individuálnych vstupov (platí len pre osobu, na ktoré meno je permanentka vystavená)',
                'Platnosť 180 dní od zakúpenia',
                'Vhodné pre ľudí, ktorí chcú chodiť pravidelne',
                'Zvýhodnená cena',
                'Platí na individuálne vstupy pre dospelých aj individuálne vstupy s deťmi',
            ],
            link: `/buy-season-ticket/${this.seasonTicketId}`,
            buttonLabel: this.translateService.instant('button.buy'),
        },
        {
            title: 'Permanentka prenájom jaskyne',
            price: 200,
            subTitle: '',
            info: [
                '5 vstupov na prenájom celej jaskyne',
                'Platnosť 180 dní od zakúpenia',
                'Vhodné pre rodiny s deťmi, ktoré chcú chodiť pravidelne',
                'Zvýhodnená cena',
            ],
            link: '/buy-season-ticket/2',
            buttonLabel: this.translateService.instant('button.buy'),
        },
        {
            title: 'ZŤP indiv. vstup',
            price: 5,
            subTitle: '',
            info: ['Zvýhodnená cena'],
            link: '/reservation',
            buttonLabel: this.translateService.instant('button.book'),
        },
        {
            title: 'Školy, Škôlky, Jasličky',
            price: 'Na dopyt',
            hideCurrencySymbol: true,
            subTitle: '',
            info: [
                'Individuálne ceny - pre zistenie ceny nás kontaktujte',
                'Učiteľky zdarma (maximálne 3)',
                'Celá jaskyňa vyhradená pre jednu skupinu',
                'Hračky v jaskyni zabezpečené',
                'Pre deti sú k dispozícií v šatni skrinky',
            ],
            link: '/reservation',
            buttonLabel: this.translateService.instant('button.book'),
        },
        {
            title: 'Darčekové poukážky',
            price: 0,
            subTitle: '',
            info: [
                'Sami si vyberiete sumu darčekovej poukážky',
                'Vhodný darček pre každú vekovú skupinu',
                'Darčekovú poukážku Vám vytlačíme alebo pošleme mailom (sami si zvolíte)',
                'Darčekovú poukážku je možné kúpiť len osobne na recepcii',
                'Sumu na darčekovej poukážke je možné použiť len raz',
            ],
            link: '/reservation',
            buttonLabel: this.translateService.instant('button.book'),
        },
    ];
}
