<!--==============================
    Appointment Area
    ==============================-->
<section class="bg-light-3 space h-100">
    <div class="container">
        <div class="row gx-60">
            <div class="col wow fadeInUp" data-wow-delay="0.3s">
                <div class="row">
                    <div class="col align-self-center mb-5">
                        <div class="row mb-4 justify-content-between text-center">
                            <div class="col">
                                @if (isLoading) {
                                <solma-loading-spinner />
                                } @else {
                                <div class="row mb-4 justify-content-between text-center">
                                    <div class="col">
                                        @if (result) {
                                        <img
                                            style="height: 200px; width: auto"
                                            class="p-4"
                                            src="assets/img/icon/checked.png" />
                                        <h2 class="h3 mt-n2">Platba prebehla úspešne.</h2>
                                        } @else {
                                        <img
                                            style="height: 200px; width: auto"
                                            class="p-4"
                                            src="assets/img/icon/x-mark.png" />
                                        <h2 class="h3 mt-n2">Vyskutla sa chyba s platbou. Prosím opakujte akciu.</h2>
                                        }
                                        <a [routerLink]="['/reservation']" class="vs-btn" type="button">
                                            Prejsť na domovskú stránku
                                        </a>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
