import { DatePipe, NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseComponent, BaseModel, InputErrorComponent, LoadingSpinnerComponent } from '@shared';
import { finalize } from 'rxjs';
import { SeasonTicketService } from '../../services';

@Component({
    selector: 'solma-app-verification',
    templateUrl: 'component.html',
    standalone: true,
    imports: [ReactiveFormsModule, TranslateModule, NgClass, InputErrorComponent, RouterLink, LoadingSpinnerComponent],
    providers: [DatePipe],
})
export class VerificationComponent extends BaseComponent {
    private readonly service = inject(SeasonTicketService);
    private readonly translateService = inject(TranslateService);
    private readonly datePipe = inject(DatePipe);

    protected errorMessage: string | null = null;
    protected successMessage: string | null = null;
    protected model: BaseModel | null = null;
    protected readonly control = new FormControl<string | null>(null, {
        validators: [Validators.minLength(1), Validators.maxLength(6)],
    });

    protected isLoading = false;

    verify() {
        if (this.control.invalid || !this.control.value) {
            return;
        }

        this.isLoading = true;
        this.errorMessage = null;
        this.successMessage = null;
        this.control.disable();
        this.subscribe(
            this.service.verify(this.control.value).pipe(
                finalize(() => {
                    this.control.enable();
                    this.isLoading = false;
                })
            ),
            {
                next: (result) => {
                    this.model = result.model;

                    if (result.error) {
                        this.errorMessage = this.translateService.instant(
                            `admin.verifySeasonTicket.messages.${result.message}`,
                            {
                                date: result.model ? this.datePipe.transform(result.model.valid_until) : null,
                            }
                        );
                        return;
                    }
                    this.successMessage = this.translateService.instant('admin.verifySeasonTicket.messages.valid', {
                        date: this.datePipe.transform(result.model.valid_until),
                        count: result.model.max_number_of_uses - result.model.used_count,
                    });
                },
            }
        );
    }
}
