import { Component, input } from '@angular/core';

@Component({
    selector: 'solma-loading-spinner',
    templateUrl: 'component.html',
    standalone: true,
    styles: ':host {background-color: inherit !important}',
})
export class LoadingSpinnerComponent {
    width = input<number>();
    height = input<number>();
}
