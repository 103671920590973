<!--==============================
    Appointment Area
    ==============================-->
<section class="bg-light-3 space h-100">
    <div class="container">
        <div class="row gx-60">
            <div class="col wow fadeInUp" data-wow-delay="0.3s">
                @if (showSuccessMessage) {
                <div class="row mb-4 justify-content-between text-center">
                    <div class="col">
                        <img style="height: 200px; width: auto" class="p-4" src="assets/img/icon/checked.png" />
                        <h2 class="h3 mt-n2">Rezervácia bola úspešne vytvorená</h2>
                    </div>
                </div>
                } @else {
                <div class="row mb-4">
                    <div class="col">
                        <a class="verificationLink" routerLink="/verification">
                            <i class="fal fa-chevron-double-right me-2"></i>
                            {{'calendar.verificationLink' | translate }}
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        @if (isLoading) {
                        <div class="position-absolute top-0 right-0 h-100 w-100 bg-white opacity-50 z-2 text-center">
                            <solma-loading-spinner />
                        </div>
                        }
                        <full-calendar [options]="calendarOptions"></full-calendar>
                    </div>
                </div>
                @if (selectedEvent) {
                <div class="row">
                    <div class="col-lg-5 col-xl-5">
                        <div class="team-about">
                            <span class="team-degi">{{ 'calendarSlotDetail.service' | translate }}</span>
                            <h3 #serviceTitle class="team-name h1">
                                {{'enum.reservationType.' + selectedEvent.service.type | translate}}
                            </h3>
                            <div class="team-info">
                                <ul class="list-unstyled">
                                    <li>
                                        <span class="title">{{ 'calendarSlotDetail.date' | translate }}:</span>
                                        {{selectedEvent.start | date}}
                                    </li>
                                    <li>
                                        <span class="title">{{ 'calendarSlotDetail.time' | translate }}:</span>
                                        {{selectedEvent.start| date : 'HH:mm'}} - {{selectedEvent.end | date : 'HH:mm'}}
                                    </li>
                                    @if (selectedEvent.disabled_price == null) {
                                    <li>
                                        <span class="title">{{ 'calendarSlotDetail.price' | translate }}:</span>
                                        {{selectedEvent.adult_price | currency}}
                                    </li>
                                    } @else {
                                    <li>
                                        <span class="title">{{ 'calendarSlotDetail.adult_price' | translate }}:</span>
                                        {{selectedEvent.adult_price | currency}}
                                    </li>
                                    <!---->
                                    @if (selectedEvent.child_price) {
                                    <li>
                                        <span class="title">{{ 'calendarSlotDetail.child_price' | translate }}:</span>
                                        {{selectedEvent.child_price | currency}}
                                    </li>
                                    }
                                    <!---->
                                    @if (selectedEvent.disabled_price) {
                                    <li>
                                        <span class="title"
                                            >{{ 'calendarSlotDetail.disabled_price' | translate }}:</span
                                        >
                                        {{selectedEvent.disabled_price | currency}}
                                    </li>
                                    }
                                    <!---->
                                    @if (selectedEvent.service.capacity) {
                                    <li>
                                        <span class="title">{{ 'calendarSlotDetail.capacity' | translate }}:</span>
                                        {{selectedEvent.occupied_capacity}} / {{selectedEvent.service.capacity}}
                                    </li>
                                    }
                                    <!---->
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-xl-7">
                        <form [formGroup]="form" class="form-style6 pt-5">
                            <div class="row">
                                @if (form.controls['num_adults']){
                                <div
                                    [ngClass]="{
                                        'col-lg-6': form.controls['num_children']
                                    }"
                                    class="col-sm-12">
                                    <div
                                        class="form-group"
                                        [ngClass]="{
                                                error: form.controls['num_adults'].touched && form.controls['num_adults'].invalid
                                            }">
                                        <label for="num_adults"
                                            >{{ 'admin.reservation.properties.num_adults' | translate }}*</label
                                        >
                                        <div class="input-group">
                                            <input
                                                formControlName="num_adults"
                                                type="number"
                                                name="num_adults"
                                                id="num_adults"
                                                class="form-control" />
                                        </div>
                                        @if (form.controls['num_adults'].touched && form.controls['num_adults'].invalid)
                                        {
                                        <solma-input-error [errors]="form.controls['num_adults'].errors" />
                                        }
                                    </div>
                                </div>
                                }
                                <!---->
                                @if (form.controls['num_children']){
                                <div class="col-lg-6 col-sm-12">
                                    <div
                                        class="form-group"
                                        [ngClass]="{
                                                error: form.controls['num_children'].touched && form.controls['num_children'].invalid
                                            }">
                                        <label for="num_children"
                                            >{{ 'admin.reservation.properties.num_children' | translate }}*</label
                                        >
                                        <div class="input-group">
                                            <input
                                                formControlName="num_children"
                                                type="number"
                                                name="num_children"
                                                id="num_children"
                                                class="form-control" />
                                        </div>
                                        @if (form.controls['num_children'].touched &&
                                        form.controls['num_children'].invalid) {
                                        <solma-input-error [errors]="form.controls['num_children'].errors" />
                                        }
                                    </div>
                                </div>
                                }
                                <!---->
                                @if (form.controls['num_disabled']){
                                <div class="col-sm-12">
                                    <div
                                        class="form-group"
                                        [ngClass]="{
                                                    error: form.controls['num_disabled'].touched && form.controls['num_disabled'].invalid
                                                }">
                                        <label for="num_disabled"
                                            >{{ 'admin.reservation.properties.num_disabled' | translate }}*</label
                                        >
                                        <div class="input-group">
                                            <input
                                                formControlName="num_disabled"
                                                type="number"
                                                name="num_disabled"
                                                id="num_disabled"
                                                class="form-control" />
                                        </div>
                                        @if (form.controls['num_disabled'].touched &&
                                        form.controls['num_disabled'].invalid) {
                                        <solma-input-error [errors]="form.controls['num_disabled'].errors" />
                                        }
                                    </div>
                                </div>
                                }
                            </div>

                            <div
                                class="form-group"
                                [ngClass]="{
                                            error:
                                                form.controls['name'].touched && form.controls['name'].invalid
                                        }">
                                <label for="name">{{ 'admin.reservation.properties.name' | translate }}*</label>
                                <div class="input-group">
                                    <input
                                        formControlName="name"
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control" />
                                </div>
                                @if (form.controls['name'].touched && form.controls['name'].invalid) {
                                <solma-input-error [errors]="form.controls['name'].errors" />
                                }
                            </div>

                            <div
                                class="form-group"
                                [ngClass]="{
                                            error:
                                                form.controls['phone'].touched && form.controls['phone'].invalid
                                        }">
                                <label for="phone">{{ 'admin.reservation.properties.phone' | translate }}*</label>
                                <div class="input-group">
                                    <input
                                        formControlName="phone"
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        class="form-control" />
                                </div>
                                @if (form.controls['phone'].touched && form.controls['phone'].invalid) {
                                <solma-input-error [errors]="form.controls['phone'].errors" />
                                }
                            </div>

                            <div
                                class="form-group"
                                [ngClass]="{
                                            error: form.controls['email'].touched && form.controls['email'].invalid
                                        }">
                                <label for="email">{{ 'admin.reservation.properties.email' | translate }}*</label>
                                <div class="input-group">
                                    <input
                                        formControlName="email"
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="form-control" />
                                </div>
                                @if (form.controls['email'].touched && form.controls['email'].invalid) {
                                <solma-input-error [errors]="form.controls['email'].errors" />
                                }
                            </div>

                            <div class="form-group">
                                <input formControlName="is_company" type="checkbox" id="is_company" name="is_company" />
                                <label for="is_company"
                                    >{{'admin.reservation.properties.is_company' | translate}}</label
                                >
                            </div>

                            @if (form.controls['is_company'].value) {
                            <!---->
                            @for (key of companyKeys; track $index) {
                            <div
                                class="form-group"
                                [ngClass]="{
                                            error:
                                                form.controls[key].touched && form.controls[key].invalid
                                        }">
                                <label [for]="key">{{ 'admin.reservation.properties.' + key | translate }}*</label>
                                <div class="input-group">
                                    <input
                                        [formControlName]="key"
                                        type="text"
                                        [name]="key"
                                        [id]="key"
                                        class="form-control" />
                                </div>
                                @if (form.controls[key].touched && form.controls[key].invalid) {
                                <solma-input-error [errors]="form.controls[key].errors" />
                                }
                            </div>
                            }
                            <!---->
                            }
                            <!---->
                            <div
                                class="widget form-group"
                                [ngClass]="{ error: discountCodeCtrl.touched && discountCodeCtrl.invalid}">
                                <label>Uplatniť zľavový kód</label>
                                <div class="search-form">
                                    <input [formControl]="discountCodeCtrl" type="text" placeholder="Zadajte kód..." />
                                    <button
                                        [disabled]="isDiscountCodeApplied"
                                        type="button"
                                        (click)="verifyDiscountCode()">
                                        @if (isDiscountCodeVerifying) {
                                        <solma-loading-spinner />
                                        } @else if(isDiscountCodeApplied) {
                                        <i class="far fa-check"></i>
                                        } @else {
                                        <i class="far fa-search"></i>
                                        }
                                    </button>
                                </div>
                                @if (discountCodeCtrl.touched && discountCodeCtrl.invalid) {
                                <solma-input-error [errors]="discountCodeCtrl.errors" />
                                } @if (discountCodeApplied) {
                                <p>Kód uplatnený: {{discountCodeApplied.percentage * 100}} %</p>
                                }
                            </div>

                            <!---->

                            <div class="row">
                                <div
                                    [ngClass]="{
                                            'col-lg-6': isSeasonTicketApplied && this.selectedEvent.service.capacity,
                                        }"
                                    class="col-sm-12">
                                    <div
                                        class="widget form-group"
                                        [ngClass]="{ error: seasonTicketCodeCtrl.touched && seasonTicketCodeCtrl.invalid }">
                                        <label>Uplatniť permanentku</label>
                                        <div class="search-form">
                                            <input
                                                [formControl]="seasonTicketCodeCtrl"
                                                type="text"
                                                placeholder="Zadajte kód..." />
                                            <button
                                                [disabled]="isSeasonTicketApplied"
                                                type="button"
                                                (click)="verifySeasonTicket()">
                                                @if (isSeasonTicketVerifying) {
                                                <solma-loading-spinner />
                                                } @else if(isSeasonTicketApplied) {
                                                <i class="far fa-check"></i>
                                                } @else {
                                                <i class="far fa-search"></i>
                                                }
                                            </button>
                                        </div>
                                        @if (seasonTicketCodeCtrl.touched && seasonTicketCodeCtrl.invalid) {
                                        <solma-input-error [errors]="seasonTicketCodeCtrl.errors" />
                                        }
                                    </div>
                                </div>
                                @if (isSeasonTicketApplied && this.selectedEvent.service.capacity &&
                                form.controls['season_ticket_apply_count']) {
                                <div class="col-lg-6 col-sm-12">
                                    <div
                                        class="form-group"
                                        [ngClass]="{
                                                        error: form.controls['season_ticket_apply_count'].touched && form.controls['season_ticket_apply_count'].invalid
                                                    }">
                                        <label for="season_ticket_apply_count"
                                            >{{ 'admin.reservation.properties.season_ticket_apply_count' | translate
                                            }}*</label
                                        >
                                        <div class="input-group">
                                            <input
                                                formControlName="season_ticket_apply_count"
                                                type="number"
                                                name="season_ticket_apply_count"
                                                id="season_ticket_apply_count"
                                                class="form-control" />
                                        </div>
                                        @if (form.controls['season_ticket_apply_count'].touched &&
                                        form.controls['season_ticket_apply_count'].invalid) {
                                        <solma-input-error
                                            [errors]="form.controls['season_ticket_apply_count'].errors" />
                                        }
                                    </div>
                                </div>
                                }
                            </div>

                            <div
                                class="form-group"
                                [ngClass]="{ error: (form.controls['confirm'].dirty || form.controls['confirm'].touched) && form.controls['confirm'].invalid }">
                                <input formControlName="confirm" type="checkbox" id="confirm" name="confirm" />
                                <label for="confirm"
                                    >{{'admin.reservation.properties.confirm' | translate}}
                                    <a
                                        target="_blank"
                                        download="Obchodné podmienky"
                                        href="assets/pdf/Obchodné podmienky.pdf"
                                        >{{'admin.reservation.properties.confirmLink' | translate}}</a
                                    >*
                                </label>
                                @if (form.controls['confirm'].touched && form.controls['confirm'].invalid) {
                                <solma-input-error [errors]="form.controls['confirm'].errors" />
                                }
                            </div>

                            <div class="product-about">
                                <p class="product-price">
                                    @if (totalPrice === basePrice) { {{basePrice | currency}}
                                    <!---->
                                    } @else { {{totalPrice | currency}}
                                    <del>{{basePrice | currency}} </del>
                                    }
                                </p>
                            </div>

                            <div class="row">
                                <div class="col-lg-auto col-sm-12">
                                    @if (isLoading) {
                                    <solma-loading-spinner />
                                    } @else {
                                    <button
                                        [disabled]="
                                            (discountCodeCtrl.value && !isDiscountCodeApplied) || (seasonTicketCodeCtrl.value && !isSeasonTicketApplied)"
                                        class="vs-btn"
                                        type="button"
                                        (click)="save()">
                                        <i class="fal fa-pen"></i>
                                        {{ 'button.bookWithPayment' | translate }}
                                    </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                } 
            }
            </div>
        </div>
    </div>
</section>
