import { Component, inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LoadingSpinnerComponent } from '@shared';
import { PaymentService } from '../../services';
import { PaymentVerificationComponent } from '../payment-verification';

@Component({
    selector: 'solma-app-reservation-payment-verification',
    templateUrl: '../payment-verification/component.html',
    standalone: true,
    imports: [LoadingSpinnerComponent, RouterLink],
})
export class ReservationPaymentVerificationComponent extends PaymentVerificationComponent implements OnInit {
    protected override navigationRoute = '/reservation';
    private readonly service = inject(PaymentService);
    protected paymentVerification = this.service.reservationPaymentNotification.bind(this.service);
}
